import { CreateOnDemandUpdateData } from "models";

export interface CreateOnDemandUpdateRequest {
  companyId: number,
  databaseStopVersion: string,
  targetUpdateDate: Date,
  overrideUpdateWindow: boolean,
  overrideReason: string,
  isReplicationDatabase: boolean
}

export const toCreateOnDemandUpdateRequests = (models: CreateOnDemandUpdateData[]) : CreateOnDemandUpdateRequest[] => models.map(toCreateOnDemandUpdateRequest);

const toCreateOnDemandUpdateRequest = (model: CreateOnDemandUpdateData) : CreateOnDemandUpdateRequest => ({
  companyId: model.selectedDatabase.companyId,
  databaseStopVersion: model.version,
  targetUpdateDate: new Date(),
  overrideUpdateWindow: true,
  overrideReason: '',
  isReplicationDatabase: model.selectedDatabase.replicationEnabled
});