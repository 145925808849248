import React from 'react';
import { Layout, Result } from '@iqmetrix/antd';
import { Option } from 'functional-ts-primitives';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { UnauthenticatedPage, LoadingPage, ScheduledUpdatesPage, CreateUpdatePage, VersionSelectPage, UpdateDetailPage, ManageDatabasesPage, EditTagPage, CreateTagPage } from 'pages';
import { TopBar } from 'components';
import { routes } from 'shared/routes';
import { Provider } from 'react-redux';
import { store } from 'store';
import { AppInsightsContext, AppInsightsErrorBoundary, withAITracking } from '@microsoft/applicationinsights-react-js';
import { appInsightsReactPlugin } from 'shared/appInsights';
import { MsalAuthenticationResult, MsalAuthenticationTemplate, MsalProvider, useMsal } from "@azure/msal-react";
import { msalInstance, redirectRequest } from "shared/authConfig";
import { InteractionType } from "@azure/msal-browser";

const { Content } = Layout

const LoadingComponent = () =>
<Layout>
  <TopBar accountInfo={Option.none()} logoutAction={() => Promise.resolve()}></TopBar>
  <Content>
      <LoadingPage />
  </Content>
</Layout>

const ErrorComponent = (result: MsalAuthenticationResult) =>
<Layout>
  <TopBar accountInfo={Option.none()} logoutAction={() => Promise.resolve()}></TopBar>
  <Content>
      <UnauthenticatedPage authError={Option.fromNullable(result.error)} loginAction={result.login} />
  </Content>
</Layout>

const AuthenticatedComponent = () => {
  const { accounts, instance } = useMsal()

  instance.setActiveAccount(accounts[0])

  return <Layout>
    <TopBar accountInfo={Option.fromNullable(accounts[0])} logoutAction={() => msalInstance.logoutRedirect({ account: accounts[0] })}></TopBar>
    <Content>
      {getContentFromRoute()}
    </Content>
  </Layout>
}

export const InnerApp = () =>
  <AppInsightsErrorBoundary onError={() => <Result status="500" />} appInsights={appInsightsReactPlugin}>
    <AppInsightsContext.Provider value={appInsightsReactPlugin}>
      <Provider store={store}>
        <Router>
          <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              authenticationRequest={redirectRequest}
              loadingComponent={LoadingComponent}
              errorComponent={ErrorComponent}>
              <AuthenticatedComponent />
            </MsalAuthenticationTemplate>
          </MsalProvider>
        </Router>
      </Provider>
    </AppInsightsContext.Provider>
  </AppInsightsErrorBoundary>;

const getContentFromRoute = () =>
  <Switch>
    <Route exact path={routes.scheduledUpdates.path} component={ScheduledUpdatesPage} />
    <Route exact path={routes.createUpdate.path} component={CreateUpdatePage} />
    <Route exact path={routes.manageDatabases.path} component={ManageDatabasesPage} />
    <Route exact path={routes.versionSelect.path} component={VersionSelectPage} />
    <Route exact path={routes.updateDetailPage.path} component={UpdateDetailPage} />
    <Route exact path={routes.editTag.path} component={EditTagPage} />
    <Route exact path={routes.createTag.path} component={CreateTagPage} />
    <Route component={() => <Result status="404" />} />
  </Switch>

export const App = withAITracking(appInsightsReactPlugin, InnerApp);