import { UpdateDetail, UpdateWindow, UpdateStatusTypes, UpdateDatabase } from "models";
import { Option } from "functional-ts-primitives";
import moment from 'moment';

export interface DatabaseInfoResponse {
  companyId: number,
  databaseName: string
  serverName: string
  databaseVersion: string
  replicationEnabled: boolean
  updateWindow: UpdateWindowTimeResponse
}

export interface UpdateWindowTimeResponse {
  startTimeUtc: string
  endTimeUtc: string
}

export interface UpdateDetailResponse {
    scheduleId: string,
    status: string
    database: DatabaseInfoResponse,
}

export const toUpdateDetails = (models: UpdateDetailResponse[]) : UpdateDetail[] =>
    models.map(model => toUpdateDetail(model));

const toUpdateDetail = (model: UpdateDetailResponse) : UpdateDetail => ({
    key: model.scheduleId,
    scheduleId: model.scheduleId,
    database: toUpdateDatabase(model.database),
    status: (UpdateStatusTypes as any)[model.status]
});


const toUpdateDatabase = (model: DatabaseInfoResponse) : UpdateDatabase => ({
  companyId: model.companyId,
  name: model.databaseName,
  server: model.serverName,
  currentVersion: model.databaseVersion,
  replicationEnabled: model.replicationEnabled,
  updateWindow: Option.fromNullable(model.updateWindow).map(window => toUpdateWindow(window)),
  tags: []
});

const toUpdateWindow = (model: UpdateWindowTimeResponse) : UpdateWindow => ({
  startUtc: moment.utc(model.startTimeUtc, "hh:mm:ss").toDate(),
  endUtc: moment.utc(model.endTimeUtc, "hh:mm:ss").toDate()
});