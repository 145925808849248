import { Guid } from "guid-typescript";

export interface CreateTagRequest {
  id: string;
  name: string;
  companyIds: number[];
}

export const toCreateTagRequest = (id: Guid, name: string, companyIds: number[]): CreateTagRequest => ({
  id: id.toString(),
  name: name,
  companyIds: companyIds
});
