import React from 'react';
import { Table, Checkbox, Badge, BadgeStyles, Button, Modal, Tooltip, Select, Alert, Typography } from '@iqmetrix/antd';
import { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { Option } from 'functional-ts-primitives';
import { ManageDatabase } from '../../models/ManageDatabase';
import styles from "./ManageDatabases.module.scss";
import { uniqBy, orderBy} from "lodash";
import { ManageTag, DatabaseTagsData, toVersionNumberStringOption, compareVersionNumbers, VersionNumber } from 'models';
import { ServiceError } from 'errors';
import { addTagsToDatabases, updateDatabaseTags } from 'composition';
import { useStore } from 'store';
import { useVT } from 'virtualizedtableforantd4';
import { useColumnSearchFilterProps } from 'hooks';

const {Text} = Typography;

export const ManageDatabases: React.FC<{
    databases: Option<ManageDatabase[]>,
    databaseTags: Option<ManageTag[]>,
    scroll?: {
        x?: number | true | string;
        y?: number | string;
    }
}> = props => {
    const data = props.databases.valueOrEmpty();
    const tags = uniqBy(props.databases.valueOrEmpty().flatMap(database => database.tags).map(tag => ({ text: tag.tagName, value: tag.key })), value => value.value);
    const name = uniqBy(props.databases.valueOrEmpty().map(database => database.name).map(name => ({ text: name, value: name })), value => value.value);
    const servers = uniqBy(props.databases.valueOrEmpty().map(database => database.server).map(server => ({ text: server, value: server })), value => value.value);
    const currentVersion = uniqBy(props.databases.valueOrEmpty().map(database => database.currentVersion).map(currentVersion => ({ text: toVersionNumberStringOption(currentVersion).valueOrDefault(() => 'None'), value: currentVersion })), value => value.text);
    const accountManager = uniqBy(props.databases.valueOrEmpty().map(database => database.accountManager).map(accountManager => ({ text: accountManager, value: accountManager })), value => value.value);
    const carrier = uniqBy(props.databases.valueOrEmpty().map(database => database.carrier).map(carrier => ({ text: carrier, value: carrier })), value => value.value);
    const category = uniqBy(props.databases.valueOrEmpty().map(database => database.category).map(category => ({ text: category, value: category })), value => value.value);
    const doors = uniqBy(props.databases.valueOrEmpty().map(database => database.doors).map(doors => ({ text: doors.toString(), value: doors })), value => value.value);
    const [isUpdatingTagsForSingleDatabase, setIsUpdatingTagsForSingleDatabase] = React.useState<boolean>(false);
    const [isAddTagsModalVisible, setIsAddTagsModalVisible] = React.useState<boolean>(false);
    const [selectedDatabaseTags, setSelectedDatabaseTags] = React.useState<string[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<string[]>([]);
    const [addUpdateTagError, setAddUpdateTagError] = React.useState<Option<ServiceError>>(Option.none());
    const serverColumnProps = useColumnSearchFilterProps('server', servers);
    const nameColumnProps = useColumnSearchFilterProps('name',name);
    const doorsColumnProps = useColumnSearchFilterProps('doors', doors);
    const currentVersionColumnProps = useColumnSearchFilterProps('currentVersion', currentVersion);
    const accountManagerColumnProps = useColumnSearchFilterProps('accountManager', accountManager);
    const carrierColumnProps = useColumnSearchFilterProps('carrier', carrier);
    const categoryColumnProps = useColumnSearchFilterProps('category', category);
    const store = useStore();
   
  React.useEffect(() => {
      setSelectedRowKeys([]);
  }, [props.databases, props.databaseTags])

  const onManageDatabaseRowSelectionChange = (rowKeys: string[]) => { setSelectedRowKeys(rowKeys); };

  const rowSelection = {
    selectedRowKeys,
    onChange: onManageDatabaseRowSelectionChange,
  } as TableRowSelection<Record<string, any>>;

  const onDatabaseTagSelectionChange = (values: string[]) => {
    setSelectedDatabaseTags(values);
  }
  const saveTags = () => {
    const databaseTags: DatabaseTagsData[] = data.filter(d => selectedRowKeys.includes(d.key)).map(d => ({ companyId: d.companyId, tagIds: selectedDatabaseTags }));

    if (isUpdatingTagsForSingleDatabase) {
      updateDatabaseTags(store, databaseTags[0])
        .applyIfFailure(error => setAddUpdateTagError(Option.some(error)));
    } else {
      addTagsToDatabases(store, databaseTags)
        .applyIfFailure(error => setAddUpdateTagError(Option.some(error)));
    }
    setSelectedRowKeys([]);
    setIsAddTagsModalVisible(false);
  }

  const addTags = () => {
    setSelectedDatabaseTags([]);
    setIsAddTagsModalVisible(true);
    setIsUpdatingTagsForSingleDatabase(false);
  }
  const onDatabaseNameClick = (record: ManageDatabase) => {
    setIsUpdatingTagsForSingleDatabase(true);
    setIsAddTagsModalVisible(true);
    setSelectedDatabaseTags(record.tags.map(tag => tag.key));
    setSelectedRowKeys([record.key]);
  }

  const onModalCancel = () => {
    setIsAddTagsModalVisible(false); 
    setSelectedRowKeys([]);
  }

  const columns: ColumnsType<ManageDatabase> = [
    {
      title: 'Database',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      render: (name: string, record: ManageDatabase) => 
        <React.Fragment>
          <Button data-testid={record.key} style={{wordWrap: 'break-word', wordBreak: 'break-word'}} type="link" onClick={() => onDatabaseNameClick(record)}>{name}</Button> <Text className={styles.replicationText}>{record.replicationEnabled ? "(replication)" : ""}</Text>
        </React.Fragment>,
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...nameColumnProps
    },
    {
      title: 'Acc. Manager',
      dataIndex: 'accountManager',
      sorter: (a, b) => a.accountManager.localeCompare(b.accountManager),
      ...accountManagerColumnProps,
      ellipsis: true,
    },
    {
      title: 'Carrier',
      dataIndex: 'carrier',
      sorter: (a, b) => a.carrier.localeCompare(b.carrier),
      ...carrierColumnProps,
      ellipsis: true,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      sorter: (a, b) => a.category.localeCompare(b.category),
      ...categoryColumnProps,
      ellipsis: true,
    },
    {
      title: 'Doors',
      dataIndex: 'doors',
      sorter: (a, b) => a.doors - b.doors,
      filters: orderBy(doors, 'text', 'asc'),
      width: 120,
      ...doorsColumnProps
    },
    {
      title: 'Current Version',
      dataIndex: 'currentVersion',
      render: (currentVersion: Option<VersionNumber>) => toVersionNumberStringOption(currentVersion).valueOrDefault(() => ''),
      sorter: (a, b) => compareVersionNumbers(a.currentVersion, b.currentVersion),
      ellipsis: true,
      ...currentVersionColumnProps,
      onFilter: (value: any, record: ManageDatabase) => compareVersionNumbers(record.currentVersion, value) == 0
    },
    {
      title: 'Server',
      dataIndex: 'server',
      sorter: (a, b) => a.server.localeCompare(b.server),
      ellipsis: true,
      ...serverColumnProps
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      render: (tags: { key: string, tagName: string }[]) => {
        let sortedTags = orderBy(tags, 'tagName', 'asc');
        return <React.Fragment>{sortedTags[0]?.tagName && <Badge count={sortedTags[0]?.tagName} style={BadgeStyles.Default} />} <Tooltip title={sortedTags.map(t => t.tagName).slice(1).join(', ')}>{sortedTags.length - 1 > 0 && <Badge count={`${sortedTags.length - 1} more`} style={BadgeStyles.Default} />}</Tooltip></React.Fragment>;
      },
      filters: orderBy(tags, 'text', 'asc'),
      onFilter: (value, record) => record.tags.flatMap(tag => tag.key).includes(value as string),
      filterMultiple: true
    },
  ];

  const [ virtualizeTable ] = useVT(() => ({ scroll: { y: 600 } }), []);
  return <React.Fragment>
    {addUpdateTagError.match(error => <Alert
      aria-label="add update tag error"
      message={<span>An error occurred when attempting to add tags to a database. {error.message}</span>}
      type="error"
      showIcon
    />,
      () => null)}
    <Modal
      title="Add tags to databases"
      centered
      visible={isAddTagsModalVisible}
      onCancel={onModalCancel}
      keyboard
      footer={[
        <Button key="back" aria-label="cancel add tags button" onClick={onModalCancel}>
          Cancel
      </Button>,
        <Button key="submit" aria-label="save add tags button" type="primary" onClick={saveTags}>
          Save
      </Button>,
      ]}
    >
      <div className={styles.addTagsModal}>
        Tags
        <Select data-testid="select-tags" mode="multiple" placeholder="Please select" value={selectedDatabaseTags} className={styles.tagsSelect} onChange={(value) => onDatabaseTagSelectionChange(value)} filterOption={(value:string, option:any) => option.children?.toLowerCase().indexOf(value.toLowerCase()) >= 0}>
          {orderBy(props.databaseTags.valueOrEmpty(), 'tagName', 'asc').map(tag => <Select.Option data-testid={tag.key} key={tag.key} value={tag.key}>{tag.tagName}</Select.Option>)}
        </Select>
      </div>
    </Modal>
    <div className={styles.actionBar}>
      <React.Fragment>{selectedRowKeys.length} of {data.length} databases selected</React.Fragment>
      <div aria-label="addTags button div" className={selectedRowKeys.length > 0 ? styles.showAddTagsButton : styles.hideAddTagsButton}>
        <Button type="default" aria-label="addTags button" onClick={addTags}>Add tags</Button>
      </div>
    </div>
    <Table
      aria-label="list of databases"
      loading={!props.databases.hasValue() || !props.databaseTags.hasValue()}
      dataSource={data}
      columns={columns}
      pagination={false}
      rowSelection={rowSelection}
      rowKey={row => row.key}
      scroll={props.scroll} 
      components={virtualizeTable} 
      />
  </React.Fragment>;
}
