import { Reducer } from "./builder";
import { Option } from "functional-ts-primitives";
import { ManageDatabase } from "../models/ManageDatabase";

export interface EditTagState {
  id: Option<string>;
  name: Option<string>;
  databases: ManageDatabase[];
}

export const [editTagReducer, editTagActions] = Reducer.withState<EditTagState>(
  {
    id: Option.none<string>(),
    name: Option.none<string>(),
    databases: [],
  }
)
  .withAction("clearEditTag")
  .map((state) => ({
    id: Option.none<string>(),
    name: Option.none<string>(),
    databases: [],
  }))
  .withAction("setEditTagName")
  .merge<string>((state, name) => ({ name: Option.some(name) }))
  .withAction("setEditTagId")
  .merge<string>((state, id) => ({ id: Option.some(id) }))
  .withAction("setEditTagDatabases")
  .merge<ManageDatabase[]>((state, databases) => ({ databases: databases }))
  .build();
