import { useDispatch, useSelector } from "react-redux";
import { createStore as createReduxStore, combineReducers, Dispatch, compose } from "redux";
import { createUpdateActions, createUpdateReducer, CreateUpdateState } from "./createUpdate";
import { updateDetailActions, updateDetailReducer, UpdateDetailState } from './updateDetail';
import { pendingCancellationsActions, pendingCancellationsReducer, PendingCancellationsState } from './pendingCancellations';
import { editTagActions, editTagReducer, EditTagState} from './editTag';
import { databasesStoreActions, databasesStoreReducer, DatabasesStoreState } from "./databasesStore";
import { tagStoreActions, tagsStoreReducer, TagsStoreState } from "./tagsStore";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export interface StoreState {
  createUpdate: CreateUpdateState,
  updateDetail: UpdateDetailState,
  pendingCancellations: PendingCancellationsState,
  editTag: EditTagState,
  tagsStore: TagsStoreState,
  databasesStore: DatabasesStoreState
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const createStore = () => createReduxStore(
  combineReducers<StoreState>({
    createUpdate: createUpdateReducer,
    updateDetail: updateDetailReducer,
    pendingCancellations: pendingCancellationsReducer,
    editTag: editTagReducer,
    tagsStore: tagsStoreReducer,
    databasesStore: databasesStoreReducer
    }),
    composeEnhancers());

export const store = createStore();

export const dispatchActions = (dispatch: Dispatch) => ({
  ...createUpdateActions(dispatch),
  ...updateDetailActions(dispatch),
  ...pendingCancellationsActions(dispatch),
  ...editTagActions(dispatch),
  ...tagStoreActions(dispatch),
  ...databasesStoreActions(dispatch)
})

export const useStoreDispatch = () => dispatchActions(useDispatch());

export const storeActions = dispatchActions(store.dispatch);

export const useStoreSelector = <TReturn extends any>(selector: (state: StoreState) => TReturn) => {
  return useSelector<StoreState, TReturn>(selector);
}

export type StoreType = typeof store & { actions: typeof storeActions };

export const useStore = () : StoreType => ({
  ...store,
  actions: storeActions
});