import React from 'react';
import { Card } from '@iqmetrix/antd';
import { DatabaseSelector, OptionMatch } from "components";
import { Link } from 'react-router-dom';
import { routes } from 'shared/routes';
import { useStoreDispatch, useStoreSelector } from 'store';
import { Option, Result, ResultPromise } from 'functional-ts-primitives';
import { useResultEffectAsync } from 'hooks';
import { getUpdateDatabasesPriorToVersion, getAllTags } from 'services';
import { LazyResultMatch, ServiceAlert } from 'components';
import { ServiceError } from 'errors';
import { UpdateDatabase } from 'models';
import { transformUpdateDatabasesToIncludeTags } from './transformUpdateDatabasesToIncludeTags';

export const UpdateDatabasesCard : React.FC = () =>
{
  const { setCreateUpdateSelectedDatabases, setCreateUpdateCachedDatabases , clearCreateUpdateCachedDatabases} = useStoreDispatch();
  const selectedDatabases = useStoreSelector(state => state.createUpdate.selectedDatabases.map(x => x.companyId));
  const versionOption = useStoreSelector(state => state.createUpdate.version);
  const cachedDatabases = useStoreSelector(state => state.createUpdate.cachedDatabases);
  const [ dataResult, refreshData ] =
    useResultEffectAsync(() =>
      Promise.all([
        versionOption.match<ResultPromise<UpdateDatabase[], ServiceError>>(
          version => cachedDatabases.match(databases => Result.successAsync(async () => databases), () => getUpdateDatabasesPriorToVersion(version)).doIfSuccessful(databases => setCreateUpdateCachedDatabases([ version, databases ])),
          () => Result.successAsync(async () => [])),
        getAllTags()
        ]).then(([databasesResult, tagsResult]) => databasesResult.bind(databases => tagsResult.map(tags => ({ Databases: transformUpdateDatabasesToIncludeTags(databases, tags), Tags: tags }))))
    , [versionOption]);

  const doRefreshData = () : void => {
    clearCreateUpdateCachedDatabases();
    refreshData();
  }
  return  <Card title="Databases">
              <OptionMatch 
                  option={versionOption}
                  some={_  => <LazyResultMatch result={dataResult}
                    success={data => <DatabaseSelector databases={Option.some(data.Databases)} tags={Option.some(data.Tags)} selectedDatabases={selectedDatabases} onChange={setCreateUpdateSelectedDatabases} onRequestReload={doRefreshData} scroll={({ y: "calc(100vh - 448px)" })} />}
                    failure={error => <ServiceAlert serviceError={error} type="error" message="An error occurred when attempting to retrieve the list of available databases." onTryAgain={refreshData} />}
                    loading={() => <DatabaseSelector databases={Option.none()} tags={Option.none()} onChange={setCreateUpdateSelectedDatabases} onRequestReload={doRefreshData} scroll={({ y: "calc(100vh - 448px)" })} />} />
                  }
                  none={() => <React.Fragment>Add a <Link to={routes.versionSelect.get()} aria-label="select update to version">version</Link> to access available database(s).</React.Fragment>}/>
          </Card>
}