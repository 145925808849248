import { Tabs, Button, Typography } from '@iqmetrix/antd';
import { PageLayout } from '@iqmetrix/layout';
import { AppPageHeader, LazyResultMatch, ServiceAlert } from "components";
import { ScheduledUpdatesTable } from 'components/ScheduledUpdatesTable';
import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'shared/routes';
import { getScheduledUpdates } from "services";
import { useResultEffectAsync } from "hooks";
import moment from 'moment';
import { useStoreDispatch } from 'store';
import { Option } from "functional-ts-primitives";

const { Paragraph } = Typography;

export const ScheduledUpdatesPage: React.FC = () => {
    const { clearCreateUpdate } = useStoreDispatch();
    const [upcomingScheduledUpdatesResult, getUpcomingScheduledUpdates] = useResultEffectAsync(() => getScheduledUpdates(moment().add(1, 'days').startOf('day'), null));
    const [previousScheduledUpdatesResult, getPreviousScheduledUpdates] = useResultEffectAsync(() => getScheduledUpdates(moment().subtract(3, 'months').startOf('day'), moment().endOf('day')));

    return <PageLayout size="full">
        {{
            header:
                <AppPageHeader
                    title="Scheduled updates"
                    extra={
                      <React.Fragment>
                        <Link to={routes.manageDatabases.get()}>
                            <Button type="link" aria-label="manage databases button">Manage databases</Button>
                        </Link>
                        <Link to={routes.createUpdate.get()}>
                            <Button type="primary" aria-label="create update button" onClick={clearCreateUpdate}>Create update</Button>
                        </Link>
                      </React.Fragment>
                    } />,
            content: [
                {
                    primary:
                        <div aria-label="scheduled updates page">
                        <Tabs>
                          <Tabs.TabPane tab="Upcoming" key="1">
                            <Paragraph>List of scheduled updates (version, update date and the number of databases).</Paragraph>
                                    <LazyResultMatch
                                        result={upcomingScheduledUpdatesResult}
                                        success={scheduledUpdates => <ScheduledUpdatesTable data={Option.some(scheduledUpdates)} scroll={({ y: "calc(100vh - 379px)" })} />}
                                        failure={error =>
                                            <ServiceAlert serviceError={error} type="error" message="An error occurred when attempting to retrieve upcoming scheduled database updates." onTryAgain={getUpcomingScheduledUpdates} />
                                        }
                                        loading={() => <ScheduledUpdatesTable data={Option.none()}/>}/>
                          </Tabs.TabPane>
                          <Tabs.TabPane tab="Previous" key="2">
                            <Paragraph>List of scheduled updates (version, update date and the number of databases).</Paragraph>
                                    <LazyResultMatch
                                        result={previousScheduledUpdatesResult}
                                        success={scheduledUpdates => <ScheduledUpdatesTable data={Option.some(scheduledUpdates)} scroll={({ y: "calc(100vh - 379px)" })} />}
                                        failure={error =>
                                            <ServiceAlert serviceError={error} type="error" message="An error occurred when attempting to retrieve previous scheduled database updates." onTryAgain={getPreviousScheduledUpdates} />
                                        }
                                        loading={() => <ScheduledUpdatesTable data={Option.none()}/>} />
                          </Tabs.TabPane>
                        </Tabs>
                      </div>
                }
            ]
        }}
    </PageLayout >
};