import { Alert, Button, Col, message, Row } from "@iqmetrix/antd";
import { PageLayout } from "@iqmetrix/layout";
import { AppPageHeader, LazyResultMatchMemo, ServiceAlert } from "components";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { routes } from "shared/routes";
import { Option } from "functional-ts-primitives";
import { ServiceError } from "errors";
import { CreateTagNameCard } from "./CreateTagNameCard";
import { CreateTagDatabasesCard } from "./CreateTagDatabasesCard";
import { useDatabasesAndTagsSelector, createTag } from "composition";
import { useStore } from "store";


export const CreateTagPage : React.FC = () => {
  const [ isSaving, setIsSaving ] = useState<boolean>();
  const [ saveError, setSaveError ] = useState<Option<ServiceError>>(Option.none());
  const [ tagName, setTagName ] = useState<Option<string>>(Option.none());
  const [ selectedDatabases, setSelectedDatabases ] = useState<number[]>([]);
  const [ databasesResult, refreshDatabases ] = useDatabasesAndTagsSelector();
  const store = useStore();

  const history = useHistory();
  const enableSave = tagName.hasValue();
  const executeSave = () => {
    setIsSaving(true);
    tagName.applyIfSome(tagName =>
      createTag(store, tagName, selectedDatabases)
        .doIfFailure(error => setSaveError(Option.some(error)))
        .doAlways(() => setIsSaving(false))
        .doIfSuccessful(() => {
          message.success("Changes saved.");
          history.replace(routes.manageDatabases.get());
        }));
  }

  return (
    <PageLayout size="full">
      {{
        header: (
          <AppPageHeader
            title="Create Tag"
            extra={
              <React.Fragment>
                <Link to={routes.manageDatabases.get()}>
                  <Button type="default" aria-label="cancel button" disabled={isSaving}>Cancel</Button>
                </Link>
                <Button type="primary" aria-label="save button" disabled={!enableSave} loading={isSaving} onClick={executeSave}>Save</Button>
              </React.Fragment>
            }
          />
        ),
        messages: saveError.match(
          error => <Alert type="error" message="An error occurred when attempting to create your tag." closable={true} onClose={() => setSaveError(Option.none())} aria-label="An error occurred on save alert" />,
          () => null),
        content: [
          {
            primary: (
              <Row gutter={[14, 17]} aria-label="edit tag page">
                <Col span={18}>
                  <LazyResultMatchMemo
                    result={databasesResult}
                    success={databases => <CreateTagDatabasesCard databases={Option.some(databases)} selectedDatabases={selectedDatabases} onChange={setSelectedDatabases} />}
                    failure={error => 
                      <ServiceAlert serviceError={error} type='error' message="An error occurred when trying to retrieve database information." onTryAgain={refreshDatabases} />}
                    loading={() => <CreateTagDatabasesCard databases={Option.none()} selectedDatabases={selectedDatabases} onChange={setSelectedDatabases} />}
                    deps={[databasesResult, selectedDatabases, setSelectedDatabases, refreshDatabases]} />
                </Col>
                <Col span={6}>
                  <CreateTagNameCard
                    onValueChanged={value => setTagName(Option.create(() => value !== "", () => value))} />
                </Col>
              </Row>
            ),
          },
        ],
      }}
    </PageLayout>
  );
};