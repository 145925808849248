import { Checkbox, Button, Menu } from "@iqmetrix/antd";
import { FilterDropdownProps, ColumnType } from "antd/es/table/interface";
import { get, sortBy } from "lodash";
import { useState } from 'react';


export const useColumnSearchFilterProps = (dataIndex: string, options: { text: string, value: any }[]): ColumnType<any> => {
    const [searchTerm, setSearchTerm] = useState('');
    options = sortBy(options, ['value']);
    return ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, visible }: FilterDropdownProps) => (
            <div>
                <div className="filter-dropdown">
                    <input type="text" placeholder="Search" onChange={event => { setSearchTerm(event.target.value) }} />
                    {options.filter((Option) => {
                        if (searchTerm === "" || Option.text.toLowerCase().includes(searchTerm.toLowerCase()))
                            return true;
                        return false;
                    }).map((option, key) => {
                        return (
                            <div className="user" key={key}>
                                <Checkbox checked={selectedKeys.includes(option.value)} onChange={e => {
                                    if (e.target.checked)
                                        setSelectedKeys([...selectedKeys, option.value])
                                    else
                                        setSelectedKeys(selectedKeys.filter(item => item !== option.value))

                                }
                                }>{option.text}</Checkbox>

                            </div>);
                    })}
                </div>
                <div>
                    <Menu>
                        <Button type="primary" style={{ float: "right" }} onClick={confirm} size="small">
                            OK
                        </Button>

                        <Button type="link" disabled={selectedKeys.length === 0} onClick={clearFilters}> Reset </Button>
                    </Menu>
                </div>
            </div>
        ),
        onFilter: (value, record) => get(record, dataIndex).toString().toLowerCase() === value.toString().toLowerCase(),
        filterMultiple: true,

    });
}