import { ServiceError } from 'errors';
import { Result, ResultPromise, Unit } from 'functional-ts-primitives';
import { getManageDatabases } from 'services';
import { StoreType } from 'store';

export const populateDatabases = (store: StoreType) : ResultPromise<Unit, ServiceError> =>
  store.getState().databasesStore.databases.match(
    result => result.match(
      success => store.getState().databasesStore.expiryDate < new Date() ? populateDatabasesFromService(store) : Result.unitAsync(),
      failure => populateDatabasesFromService(store),
      Result.unitAsync),
    () => populateDatabasesFromService(store));

const populateDatabasesFromService = (store: StoreType) : ResultPromise<Unit, ServiceError> => {
  store.actions.setDatabasesLoading();
  return getManageDatabases().do(store.actions.setDatabases, store.actions.setDatabasesError).map(_ => Unit);
}