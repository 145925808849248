import React, { useState } from 'react';
import { Button } from '@iqmetrix/antd';
import { PageLayout } from '@iqmetrix/layout';
import { AppPageHeader } from "components";
import { VersionSelector } from 'components';
import { Option } from 'functional-ts-primitives';
import { useStoreDispatch, useStoreSelector } from 'store';
import { routes } from 'shared/routes';
import { Link, useHistory } from 'react-router-dom';

export const VersionSelectPage : React.FC = props => {
    const [ selectedVersion, setSelectedVersion ] = useState<Option<string>>(useStoreSelector(state => state.createUpdate.version));
    const { setCreateUpdateVersion, clearCreateUpdateVersion } = useStoreDispatch();
    const history = useHistory();

    const onAdd = () => {
        selectedVersion.apply(version => setCreateUpdateVersion(version), () => clearCreateUpdateVersion());
        history.replace(routes.createUpdate.get());
    }

    return <PageLayout size="full">
    {{
        header:
            <AppPageHeader
                title="Add Version"
                extra={
                    <React.Fragment>
                        <Link to={routes.createUpdate.get()}>
                            <Button type="default" aria-label="cancel button">Cancel</Button>
                        </Link>
                        <Button type="primary" aria-label="add button" disabled={!selectedVersion.hasValue()} onClick={onAdd}>Add</Button>
                    </React.Fragment>
                } />,
        content: [
            {
                primary:
                <div aria-label="select version page">
                    <VersionSelector selected={selectedVersion} onChange={version => setSelectedVersion(Option.some(version))} />
                </div>
            }
        ]
    }}
    </PageLayout>;
}