import { Option } from "functional-ts-primitives";
import { SelectedDatabase, UpdateDatabase } from "models";
import { Reducer } from "./builder";

export interface CreateUpdateState {
    version: Option<string>,
    updateDate: Option<Date>,
    updateNow: boolean,
    selectedDatabases: SelectedDatabase[],
    cachedDatabases: Option<UpdateDatabase[]>
}

export const [ createUpdateReducer, createUpdateActions ] = Reducer
.withState<CreateUpdateState>(({
  version: Option.none<string>(),
  updateDate: Option.none<Date>(),
  updateNow: false,
  selectedDatabases: [],
  cachedDatabases: Option.none()
}))
.withAction("clearCreateUpdate").map(state => ({
  version: Option.none<string>(),
  updateDate: Option.none<Date>(),
  updateNow: false,
  selectedDatabases: [],
  cachedDatabases: Option.none()
}))
.withAction("setCreateUpdateVersion").merge<string>((state, version) => ({
  version: Option.some(version),
  selectedDatabases: [],
  cachedDatabases: Option.none()
}))
.withAction("clearCreateUpdateVersion").merge(state => ({
  version: Option.none<string>(),
  selectedDatabases: [],
  cachedDatabases: Option.none()
}))
.withAction("setCreateUpdateDate").merge<Date>((state, date) => ({ 
  updateDate: Option.some(date),
  updateNow: false,
}))
.withAction("clearCreateUpdateDate").merge(state => ({ 
  updateDate: Option.none<Date>(),
  updateNow: false
}))
.withAction("setCreateUpdateNow").merge<boolean>((state, updateNow) => ({ 
  updateDate: Option.none<Date>(),
  updateNow: updateNow
}))
.withAction("setCreateUpdateSelectedDatabases").merge<SelectedDatabase[]>((state, databases) => ({ selectedDatabases: databases }))
.withAction("setCreateUpdateCachedDatabases").merge<[ version: string, databases: UpdateDatabase[] ]>((state, [version, databases]) =>
  state.version.hasValue() && version === state.version.valueOrUndefined()
  ? ({
    cachedDatabases: Option.some(databases)
  })
  : ({}))
.withAction("clearCreateUpdateCachedDatabases").merge(state => ({
  cachedDatabases: Option.none<UpdateDatabase[]>()
}))
.build();