import React from 'react';
import config from 'shared/config';
import styles from './TopBar.module.scss';
import { Dropdown, Menu, CaretDownFilled, Layout } from '@iqmetrix/antd';
import { Option } from 'functional-ts-primitives';
import { AccountInfo } from '@azure/msal-browser';
const { Header } = Layout;

export const TopBar : React.FC<{accountInfo: Option<AccountInfo>, logoutAction: () => Promise<void>}> = props =>
<Header id={styles.topBar}>
    <div className={styles.title} aria-label="page title">{config.appHeader}</div>
    {props.accountInfo.match(
        accountInfo => 
            <Dropdown overlay={accountMenu(props.logoutAction)} trigger={["click"]}>
                <a href="#" aria-label="account dropdown">
                    {accountInfo.name} <CaretDownFilled width={10} />
                </a>
            </Dropdown>,
        () => null
    )}
</Header>;

const accountMenu = (logoutAction: () => Promise<void>) =>
<Menu>
    <Menu.Item key="signout" onClick={logoutAction} aria-label="sign out">
        Sign out
    </Menu.Item>
</Menu>;