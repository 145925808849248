import React from 'react';
import { match } from 'react-router';
import { routes } from 'shared/routes';
import config from "shared/config";
import { Breadcrumb } from "@iqmetrix/antd";
import { BreadcrumbProps } from "antd/es/breadcrumb";

export const getBreadcrumbs = <TParams extends {[key: string]: string}>(match: match<TParams>) : BreadcrumbProps =>
({
    separator: '>',
    routes: 
    [
        {
            path: config.landingAppUri,
            breadcrumbName: config.appHeader
        },
        ...Object
            .keys(routes)
            .map(key => (routes as {[key: string] : { path: string, breadcrumb: (params: any) => string }})[key])
            .filter(route => match.path.includes(route.path))
            .map(route => ({
                path:
                    Object.keys(match.params).reduce(
                    (path, param) =>
                        path.replace(`:${param}`, match.params[param]),
                        route.path),
                breadcrumbName: route.breadcrumb(match.params)
            }))
    ],
    itemRender: (route, params, routes, paths) =>
        route.path === config.landingAppUri
            ? <Breadcrumb.Item separator={null} href={config.landingAppUri}>{route.breadcrumbName}</Breadcrumb.Item>
            : <Breadcrumb.Item separator={null} href={`#${route.path}`}>{route.breadcrumbName}</Breadcrumb.Item>
});