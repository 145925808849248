import React from 'react';
import { Typography, Input, Card } from '@iqmetrix/antd';

const { Paragraph } = Typography;

export const CreateTagNameCard: React.FC<{
  onValueChanged: (value: string) => void
}> = props => {

  return (
    <Card title="Tag name">
      <Paragraph>Tag name</Paragraph>
      <Input
        aria-label="tag name input"
        onChange={value => props.onValueChanged(value.target.value)}
      />
    </Card>
  );
};