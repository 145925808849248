import { CreateScheduledUpdateData } from "models";

export interface CreateScheduledUpdateRequest {
  companyId: number,
  databaseStopVersion: string,
  targetUpdateDate: Date,
  isReplicationDatabase: boolean
}

export const toCreateScheduledUpdateRequests = (models: CreateScheduledUpdateData[]) : CreateScheduledUpdateRequest[] => models.map(toCreateScheduledUpdateRequest);

const toCreateScheduledUpdateRequest = (model: CreateScheduledUpdateData) : CreateScheduledUpdateRequest => ({
  companyId: model.selectedDatabase.companyId,
  databaseStopVersion: model.version,
  targetUpdateDate: model.dateUtc,
  isReplicationDatabase: model.selectedDatabase.replicationEnabled
});