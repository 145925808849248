import { ScheduledUpdateCancellationFailedData } from "models";

export interface ScheduledUpdateCancellationFailedResponse {
    scheduleId: string,
    errorMessage: string
}

export const toScheduledUpdateCancellationFailedData = (models: ScheduledUpdateCancellationFailedResponse[]) : ScheduledUpdateCancellationFailedData[] =>
    models.map(model => toScheduledUpdateCancellationFailedItem(model));

const toScheduledUpdateCancellationFailedItem = (model: ScheduledUpdateCancellationFailedResponse) : ScheduledUpdateCancellationFailedData => ({
    scheduleId: model.scheduleId,
    errorMessage: model.errorMessage
});