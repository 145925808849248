import React, { useState } from 'react';
import { Table } from '@iqmetrix/antd';
import { ColumnsType } from 'antd/es/table';
import { VersionMapping, VersionNumber, toVersionNumberStringOption, compareVersionNumbers } from 'models';
import { Option } from "functional-ts-primitives";
import { useVT } from 'virtualizedtableforantd4';
import { uniqBy } from 'lodash';
import { useColumnSearchFilterProps } from '../../hooks';

declare type Key = React.Key;

export const VersionMappingsTable: React.FC<{
    data: Option<VersionMapping[]>,
    onChange: (databaseVersion: string) => void,
    selected: Option<string>,
    scroll?: {
        x?: number | true | string;
        y?: number | string;
    }
}> = props => {

    const [selectedRowKey, setSelectedRowKey] = useState<Key[]>([props.selected.match(version => version, () => "")]);
    const oldestRQVersion = uniqBy(props.data.valueOrEmpty().map(mapping => mapping.oldestRQVersion).map(oldestRQVersion => ({ text: toVersionNumberStringOption(oldestRQVersion).valueOrDefault(() => 'None'), value: oldestRQVersion })), value => value.text);
    const databaseVersion = uniqBy(props.data.valueOrEmpty().map(mapping => mapping.databaseVersion).map(databaseVersion => ({ text: toVersionNumberStringOption(databaseVersion).valueOrDefault(() => 'None'), value: databaseVersion })), value => value.text);
    const databaseVersionColumnProps = useColumnSearchFilterProps('databaseVersion', databaseVersion);
    const oldestRQVersionColumnProps = useColumnSearchFilterProps('oldestRQVersion', oldestRQVersion);
    const onRowKeyChange = (selectedKey: Key[]) => {
        setSelectedRowKey(selectedKey);
        props.onChange(selectedKey[0].toString());
    };

    const columns: ColumnsType<VersionMapping> = [
        {
            title: 'Database Version',
            dataIndex: 'databaseVersion',
            render: (version: Option<VersionNumber>) => toVersionNumberStringOption(version).valueOrDefault(() => ''),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
            sorter: (a: VersionMapping, b: VersionMapping) => compareVersionNumbers(a.databaseVersion, b.databaseVersion),
            ...databaseVersionColumnProps,
            onFilter: (value: any, record: VersionMapping) => compareVersionNumbers(record.databaseVersion, value) === 0
        },
        {
            title: 'Newest Compatible Service Version',
            dataIndex: 'oldestRQVersion',
            render: (version: Option<VersionNumber>) => toVersionNumberStringOption(version).valueOrDefault(() => ''),
            sortDirections: ['descend', 'ascend'],
            sorter: (a: VersionMapping, b: VersionMapping) => compareVersionNumbers(a.oldestRQVersion, b.oldestRQVersion),
            ...oldestRQVersionColumnProps,
            onFilter: (value: any, record: VersionMapping) => compareVersionNumbers(record.oldestRQVersion, value) === 0
        },
    ];


    const [virtualizeTable] = useVT(() => ({ scroll: { y: 600 } }), []);
    return <Table
        dataSource={props.data.valueOrEmpty()}
        columns={columns}
        pagination={false}
        rowSelection={{ type: 'radio', selectedRowKeys: selectedRowKey, onChange: onRowKeyChange }}
        scroll={props.scroll}
        loading={!props.data.hasValue()}
        components={virtualizeTable} />;
}
