import { Dispatch } from "react";

interface ReducerData<TState, TActionFactory> {
  initialState: TState
  reducerMapper: ReducerMapper<TState>
  actionFactory: (dispatcher: Dispatch<any>) => TActionFactory
}

type ReducerMapper<TState> = (state: TState | undefined, action: ReducerAction<any>) => TState;

interface ReducerAction<TArgs> {
  type: string,
  payload: TArgs
}

export const Reducer = {
  withState: <TState>(initialState: TState) => new ReducerBuilder({ initialState, reducerMapper: (state = initialState, action) => state, actionFactory: dispatcher => ({}) })
}

class ReducerBuilder<TState, TActionFactory = {}> {
  constructor(private reducer: ReducerData<TState, TActionFactory>) {}

  withAction<TKey extends string>(type: TKey) {
    return new ActionBuilder(type, this.reducer);
  }

  build() : [ReducerMapper<TState>, (dispatcher: Dispatch<any>) => TActionFactory] {
    return [ this.reducer.reducerMapper, this.reducer.actionFactory ] 
  }
}

class ActionBuilder<TState, TActionFactory, TKey extends string> {
  constructor(private type: TKey, private reducer: ReducerData<TState, TActionFactory>) {}

  map(mapper: (state: TState) => TState) : ReducerBuilder<TState, TActionFactory & { [P in TKey] : () => void }>
  map<TArgs>(mapper: (state: TState, args: TArgs) => TState) : ReducerBuilder<TState, TActionFactory & { [P in TKey] : (args: TArgs) => void }> 
  map<TArgs>(mapper: ((state: TState) => TState) | ((state: TState, args: TArgs) => TState)) : ReducerBuilder<TState, TActionFactory & { [P in TKey] : (() => void) | ((args: TArgs) => void) }> {
    if(mapper.length === 1)
    {
      return new ReducerBuilder({
        initialState: this.reducer.initialState,
        reducerMapper: (state, action) => action.type === this.type ? (mapper as (state: TState) => TState)(this.reducer.reducerMapper(state, action)) : this.reducer.reducerMapper(state, action),
        actionFactory: (dispatcher: Dispatch<any>) => ({
          ...this.reducer.actionFactory(dispatcher),
          ...getRecord(this.type, () => dispatcher(({ type: this.type })))
        })
      });
    }
    else
    {
      return new ReducerBuilder({
        initialState: this.reducer.initialState,
        reducerMapper: (state, action) => action.type === this.type ? mapper(this.reducer.reducerMapper(state, action), action.payload) : this.reducer.reducerMapper(state, action),
        actionFactory: (dispatcher: Dispatch<any>) => ({
          ...this.reducer.actionFactory(dispatcher),
          ...getRecord(this.type, (args: TArgs) => dispatcher(({ type: this.type, payload: args })))
        })
      });
    }
  }

  merge(merger: (state: TState) => Partial<TState>) : ReducerBuilder<TState, TActionFactory & { [P in TKey] : () => void }>
  merge<TArgs>(merger: (state: TState, args: TArgs) => Partial<TState>) : ReducerBuilder<TState, TActionFactory & { [P in TKey] : (args: TArgs) => void }> 
  merge<TArgs>(merger: ((state: TState) => Partial<TState>) | ((state: TState, args: TArgs) => Partial<TState>)) : ReducerBuilder<TState, TActionFactory & { [P in TKey] : (() => void) | ((args: TArgs) => void) }> {
    if(merger.length === 1)
    {
      return this.map(state => ({ ...state, ...(merger as (state: TState) => Partial<TState>)(state)}));
    }
    else
    {
      return this.map<TArgs>((state, args) => ({ ...state, ...merger(state, args)}));
    }
  }
}

function getRecord<TKey extends string, TValue>(key: TKey, value: TValue) : { [P in TKey] : TValue }
function getRecord<TKey extends string, TValue>(key: TKey, value: TValue) {
  return ({
    [key]: value
  });
}

