import React from 'react';
import { Table } from '@iqmetrix/antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { compareVersionNumbers, ScheduledUpdate, toVersionNumber } from 'models';
import { Link } from 'react-router-dom';
import { routes } from 'shared/routes';
import { useStoreDispatch } from 'store';
import { Option } from 'functional-ts-primitives';
import { useVT } from 'virtualizedtableforantd4';
import { useColumnSearchFilterProps } from 'hooks';
import { uniqBy } from 'lodash';

export const ScheduledUpdatesTable: React.FC<{
  data: Option<ScheduledUpdate[]>, scroll?: {
    x?: number | true | string;
    y?: number | string;
  }
}> = props => {
  const { setUpdateDetailVersion, setUpdateDetailScheduleIds, setUpdateDetailRefreshDate, setUpdateDetailUpdateDate } = useStoreDispatch();
  const updateToVersion = uniqBy(props.data.valueOrEmpty().map(mapping => mapping.updateToVersion).map(updateToVersion => ({ text: updateToVersion, value: updateToVersion })), value => value.value);
  const updateToVersionColumnProps = useColumnSearchFilterProps('updateToVersion', updateToVersion);
  const numberOfDatabases = uniqBy(props.data.valueOrEmpty().map(mapping => mapping.numberOfDatabases).map(numberOfDatabases => ({ text: numberOfDatabases.toString(), value: numberOfDatabases })), value => value.value);
  const numberOfDatabasesColumnProps = useColumnSearchFilterProps('numberOfDatabases', numberOfDatabases);
  const numberOfCancelledUpdates = uniqBy(props.data.valueOrEmpty().map(mapping => mapping.numberOfCancelledUpdates).map(numberOfCancelledUpdates => ({ text: numberOfCancelledUpdates.toString(), value: numberOfCancelledUpdates })), value => value.value);
  const numberOfCancelledUpdatesColumnProps = useColumnSearchFilterProps('numberOfCancelledUpdates', numberOfCancelledUpdates);
  const user = uniqBy(props.data.valueOrEmpty().map(mapping => mapping.user).map(user => ({ text: user, value: user })), value => value.value);
  const userColumnProps = useColumnSearchFilterProps('user', user);
  const updateDate = uniqBy(props.data.valueOrEmpty().map(mapping => mapping.updateDate).map(updateDate => ({ text: updateDate.toString(), value: updateDate })), value => value.value);
  const updateDateColumnProps = useColumnSearchFilterProps('updateDate', updateDate);
  const onVersionClick = (record: ScheduledUpdate) => {
    setUpdateDetailVersion(record.updateToVersion);
    setUpdateDetailScheduleIds(record.scheduleIds);
    setUpdateDetailUpdateDate(record.updateDate);
    setUpdateDetailRefreshDate(new Date());
  }

  const columns: ColumnsType<ScheduledUpdate> = [
    {
      title: 'Update to version',
      dataIndex: 'updateToVersion',
      render: (text: string, record: ScheduledUpdate) => <Link to={routes.updateDetailPage.get(text)} onClick={() => onVersionClick(record)}>{text}</Link>,
      defaultSortOrder: 'descend',
      sortDirections: ['descend'],
      sorter: {
        compare: (a: ScheduledUpdate, b: ScheduledUpdate) => compareVersionNumbers(toVersionNumber(a.updateToVersion), toVersionNumber(b.updateToVersion)),
        multiple: 1
      },
      ...updateToVersionColumnProps
    },
    {
      title: 'Update Date',
      dataIndex: 'updateDate',
      render: (text: Date) => moment(text).utc().format('MMM DD YYYY'),
      defaultSortOrder: 'descend',
      sorter: {
        compare: (a: ScheduledUpdate, b: ScheduledUpdate) => moment(a.updateDate).unix() - moment(b.updateDate).unix(),
        multiple: 2
      },
      sortDirections: ['descend', 'ascend'],
      ...updateDateColumnProps,
    },
    {
      title: 'Total databases',
      dataIndex: 'numberOfDatabases',
      sortDirections: ['descend', 'ascend'],
      sorter: (a: ScheduledUpdate, b: ScheduledUpdate) => a.numberOfDatabases - b.numberOfDatabases,
      ...numberOfDatabasesColumnProps,
    },
    {
      title: 'Cancelled updates',
      dataIndex: 'numberOfCancelledUpdates',
      sortDirections: ['descend', 'ascend'],
      sorter: (a: ScheduledUpdate, b: ScheduledUpdate) => a.numberOfCancelledUpdates - b.numberOfCancelledUpdates,
      ...numberOfCancelledUpdatesColumnProps,
    },
    {
      title: 'User',
      dataIndex: 'user',
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      sorter: (a: ScheduledUpdate, b: ScheduledUpdate) => a.user.localeCompare(b.user),
      ...userColumnProps,
    },
  ];


  const [virtualizeTable] = useVT(() => ({ scroll: { y: 600 } }), []);
  return <Table
    dataSource={props.data.valueOrEmpty()}
    columns={columns}
    pagination={false}
    scroll={props.scroll}
    loading={!props.data.hasValue()}
    components={virtualizeTable} />;
}
