import {ManageDatabase, toVersionNumber} from "models";

export interface ManageDatabaseResponse {
    companyId: number,
    databaseName: string;
    clientManager: string;
    carrier: string;
    category: string;
    numberOfLocations: number;
    currentVersion: string;
    server: string;
    replicationEnabled: boolean;
    tags: string[]
}

export const toManageDatabases = (models: ManageDatabaseResponse[]) : ManageDatabase[] =>
    models.map(model => toManageDatabase(model));

const toManageDatabase = (model: ManageDatabaseResponse) : ManageDatabase => ({
    key: `${model.companyId}-${model.databaseName}`,
    companyId: model.companyId,
    name: model.databaseName,
    accountManager: model.clientManager,
    carrier: model.carrier,
    category: model.category,
    doors: model.numberOfLocations,
    currentVersion: toVersionNumber(model.currentVersion),
    server: model.server,
    replicationEnabled: model.replicationEnabled,
    tags: []
});