import { Card } from '@iqmetrix/antd';
import React from 'react';
import { ManageDatabase } from 'models';
import { ManageDatabaseSelector } from 'components';
import { Option } from 'functional-ts-primitives';

export const CreateTagDatabasesCard: React.FC<{
  databases: Option<ManageDatabase[]>,
  selectedDatabases: number[],
  onChange: (selectedDatabases: number[]) => void
}> = props => {
  return (
    <Card title="Databases">
      <ManageDatabaseSelector
        databases={props.databases}
        selectedDatabases={props.selectedDatabases}
        onChange={props.onChange}
        scroll={{ y: "calc(100vh - 420px)" }}
      />
    </Card>
  );
};