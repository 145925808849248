import { Reducer } from "./builder";
import { Option } from "functional-ts-primitives";
import { lazyFailure, lazyLoading, LazyResult, lazySuccess, ManageDatabase } from "models";
import { ServiceError } from "errors";
import config from "shared/config";

export interface DatabasesStoreState {
  databases: Option<LazyResult<ManageDatabase[], ServiceError>>;
  expiryDate: Date;
}

export const [databasesStoreReducer, databasesStoreActions] = Reducer.withState<DatabasesStoreState>(
{
  databases: Option.none(),
  expiryDate: new Date()
})
.withAction("setDatabasesLoading")
.map((state) => ({
  databases: Option.some(lazyLoading()),
  expiryDate: new Date()
}))
.withAction("setDatabases")
.map<ManageDatabase[]>((state, databases) => ({
  databases: Option.some(lazySuccess(databases)),
  expiryDate: getNewExpiryDate()
}))
.withAction("setDatabasesError")
.map<ServiceError>((state, error) => ({
  databases: Option.some(lazyFailure(error)),
  expiryDate: new Date()
}))
.build();

const getNewExpiryDate = () =>
{
  var expiryDate = new Date();
  expiryDate.setMinutes(expiryDate.getMinutes() + config.store.cacheExpiryInMinutes);
  return expiryDate;
}
