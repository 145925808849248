import { Tabs, Button, Alert } from '@iqmetrix/antd';
import { PageLayout } from '@iqmetrix/layout';
import { AppPageHeader, LazyResultMatch, ServiceAlert } from "components";
import { GenerateBridgeScripts } from 'components/GenerateBridgeScripts';
import { ManageDatabases } from 'components/ManageDatabases';
import { ManageTagsTable } from 'components/ManageTagsTable';
import React from 'react';
import { Option } from "functional-ts-primitives";
import { lazyResultZip } from 'models';
import { Link } from 'react-router-dom';
import { routes } from 'shared/routes';
import { useDatabasesAndTagsSelector, useTagsSelector } from 'composition';
import { getDatabaseVersions } from "services";
import { useResultEffectAsync } from "hooks";

export const ManageDatabasesPage: React.FC = () => {
  const [ databases, getManageDatabases ] = useDatabasesAndTagsSelector();
  const [ tags, getTags ] = useTagsSelector();
  const [ databaseVersions, refreshDatabaseVersions ] = useResultEffectAsync(() => getDatabaseVersions());
  const dataResult = lazyResultZip(databases, tags).map(data => ({ Databases: data[0], Tags: data[1] }));
  const [deleteTagError, setDeleteTagError] = React.useState<Option<string>>(Option.none());

  return <PageLayout size="full">
    {{
      header:
        <AppPageHeader
          title="Manage databases"
          extra={
            <Link to={routes.createTag.get()}>
              <Button type="primary" aria-label="create tag button">Create Tag</Button>
            </Link>
          } />,
      messages: deleteTagError.match(
        error => <Alert type="error" message={error} closable={true} onClose={() => setDeleteTagError(Option.none())} aria-label="An error occurred on tag deletion alert" />,
        () => null),
      content: [
        {
          primary:
            <div aria-label="manage databases page">
              <Tabs>
                <Tabs.TabPane aria-label="manage databases tab databases" tab="Databases" key="1">
                  <LazyResultMatch
                    result={dataResult}
                    success={data =>
                      <ManageDatabases
                        databases={Option.some(data.Databases)}
                        databaseTags={Option.some(data.Tags)}
                        scroll={({ y: "calc(100vh - 411px)" })} />}
                    failure={error =>
                      <ServiceAlert serviceError={error} type="error" message="An error occurred when attempting to retrieve databases." onTryAgain={getManageDatabases} />
                    }
                    loading={() => <ManageDatabases databases={Option.none()} databaseTags={Option.none()} />} />
                </Tabs.TabPane>
                <Tabs.TabPane aria-label="manage tags tab" tab="Tags" key="2">
                  <LazyResultMatch
                    result={dataResult}
                    success={data => <ManageTagsTable tags={Option.some(data.Tags)} databases={Option.some(data.Databases)} scroll={({ y: "calc(100vh - 399px)" })} onDeleteTagsError={setDeleteTagError} />}
                    failure={error =>
                      <ServiceAlert serviceError={error} type="error" message="An error occurred when attempting to retrieve tags." onTryAgain={getTags} />
                    }
                    loading={() => <ManageTagsTable tags={Option.none()} databases={Option.none()} onDeleteTagsError={setDeleteTagError} />} />
                </Tabs.TabPane>
                <Tabs.TabPane aria-label="generate bridge scripts tab" tab="Generate script" key="3">
                  <LazyResultMatch
                    result={databaseVersions}
                    success={data => <GenerateBridgeScripts versions={Option.some(data)}/>}
                    failure={error =>
                      <ServiceAlert serviceError={error} type="error" message="An error occurred when attempting to retrieve version numbers." onTryAgain={refreshDatabaseVersions} />
                    }
                    loading={() => <GenerateBridgeScripts versions={Option.none()}/>} />
                </Tabs.TabPane>
              </Tabs>
            </div>
        }
      ]
    }}
  </PageLayout >
};