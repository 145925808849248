
import { Result, Option } from "functional-ts-primitives";

interface ILazyResult<TSuccess, TFailure> {
    match: <T>(
        onSuccess: ((value: TSuccess) => T),
        onFailure: ((value: TFailure) => T),
        onLoading: (() => T))  => T,
    map: <T>(map: (value: TSuccess) => T) => LazyResult<T, TFailure>
}

export class LazyResult<TSuccess, TFailure> implements ILazyResult<TSuccess, TFailure>
{
  constructor(private _value: Option<Result<TSuccess, TFailure>>) {}

  match<T>(
    onSuccess: ((value: TSuccess) => T),
    onFailure: ((value: TFailure) => T),
    onLoading: (() => T))
  {
    return this._value.match(
        some => some.match(
            success => onSuccess(success),
            failure => onFailure(failure)
        ),
        () => onLoading());
  }

  map<T>(map: (value: TSuccess) => T)
  {
    return lazyResult(this._value.map(result => result.map(map)));
  }
}


export const lazyResult = <TSuccess, TFailure>(optionResult: Option<Result<TSuccess, TFailure>>) : LazyResult<TSuccess, TFailure> => new LazyResult(optionResult);

export const lazySuccess = <TSuccess, TFailure>(success: TSuccess) : LazyResult<TSuccess, TFailure> => lazyResult(Option.some(Result.success(success)));
export const lazyFailure = <TSuccess, TFailure>(failure: TFailure) : LazyResult<TSuccess, TFailure> => lazyResult(Option.some(Result.failure(failure)));
export const lazyLoading = <TSuccess, TFailure>() : LazyResult<TSuccess, TFailure> => lazyResult(Option.none());

export const lazyResultZip = <TSuccess1, TSuccess2, TFailure>(result1: LazyResult<TSuccess1, TFailure>, result2: LazyResult<TSuccess2, TFailure>) => 
  result1.match<LazyResult<[TSuccess1, TSuccess2], TFailure>>(
    success1 => result2.match(success2 => lazySuccess([ success1, success2 ]), failure => lazyFailure(failure), () => lazyLoading()),
    lazyFailure,
    lazyLoading);