import { Reducer } from "./builder";
import { distinct } from "shared/arrayExtensions";

export interface PendingCancellationsState {
    pendingScheduleIds: string[]
}

export const [ pendingCancellationsReducer, pendingCancellationsActions ] = Reducer
.withState<PendingCancellationsState>(({
  pendingScheduleIds: []
}))
.withAction("clearPendingCancellations").map(state => ({
  pendingScheduleIds: []
}))
.withAction("addPendingCancellations").map<string[]>((state, newPendingScheduleIds) => ({
  pendingScheduleIds: distinct(state.pendingScheduleIds.concat(newPendingScheduleIds), item => item)
}))
.withAction("removePendingCancellations").map<string[]>((state, scheduleIdsToRemove) => ({
  pendingScheduleIds: state.pendingScheduleIds.filter(item => !scheduleIdsToRemove.includes(item))
}))
.build();