import React, { useState } from 'react';
import { Card, Radio, Typography, Row } from '@iqmetrix/antd';
import moment from 'moment';
import { useStoreSelector, useStoreDispatch } from 'store';
import { Option } from 'functional-ts-primitives';
import { DatePicker } from 'antd/lib'
import { RadioChangeEvent } from 'antd/lib/radio'

const { Paragraph } = Typography;

export declare type UpdateDateMode = 'schedule' | 'updateNow';

export const UpdateDateCard : React.FC = () =>
{
    const updateDate = useStoreSelector(state => state.createUpdate.updateDate);
    const updateNow = useStoreSelector(state => state.createUpdate.updateNow);
    const { setCreateUpdateDate, clearCreateUpdateDate, setCreateUpdateNow } = useStoreDispatch();
    const [updateDateMode, setUpdateDateMode] = useState<UpdateDateMode>(updateNow ? 'updateNow' : 'schedule');
    
    const onChangeUpdateNow = (e: RadioChangeEvent) => {
        setUpdateDateMode(e.target.value);
        setCreateUpdateNow(e.target.value === 'updateNow');
    }

    const updateDateOptions = [
      { label: 'Update by date', value: 'schedule' },
      { label: 'Update now', value: 'updateNow' }
    ];

    return  <Card title="Update date">
              <Row>
                <Radio.Group 
                  options={updateDateOptions} 
                  onChange={onChangeUpdateNow} 
                  value={updateDateMode} 
                  optionType="button"
                  style={{paddingBottom:16}}/>
              </Row>
              <Row>
                {updateDateMode === 'schedule' ? 
                  <DatePicker
                      format="MMM/DD/YYYY"
                      placeholder="mmm/dd/yyyy"
                      direction="ltr"
                      disabledDate={current => current && current < moment().endOf('day')}
                      aria-label="select update date"
                      defaultValue={updateDate.map(date => moment(date)).toNullable() ?? undefined}
                      onChange={e => Option.fromNullable(e).apply(selected => setCreateUpdateDate(selected.toDate()), () => clearCreateUpdateDate())}
                      allowClear={true}
                      inputReadOnly={true}
                  /> 
                : null}

                {updateDateMode === 'updateNow' ? 
                  <Paragraph>Override the "preferred window" and start the update now.</Paragraph> 
                : null}
              </Row>
            </Card>;
}