import React from 'react';
import { Typography, Input, Card } from '@iqmetrix/antd';

const { Paragraph } = Typography;

export const EditTagNameCard: React.FC<{
  onValueChanged: (value: string) => void,
  name: string
}> = props => {

  return (
    <Card title="Tag name">
      <Paragraph>Tag name</Paragraph>
      <Input
        aria-label="tag name input"
        defaultValue={props.name}
        onChange={value => props.onValueChanged(value.target.value)}
      />
    </Card>
  );
};
