// order is necessary for previous update detail page sorting 
export enum UpdateStatusTypes {
  Cancelling = 0,
  Running = 1,
  Pending = 2,
  Scheduled = 3,
  Triggered = 4,
  Failed = 5,
  Cancelled = 6,
  Completed = 7,
}

export interface UpdateStatusTypesFilter {
  text: string;
  value: string;
}

export const getUpdateStatusTypesFilter = (): UpdateStatusTypesFilter[] => {
  let filters: Array<UpdateStatusTypesFilter> = [];
  for (let value in UpdateStatusTypes) {
    if (typeof UpdateStatusTypes[value] === "number") {
      filters.push({text:value, value: value});
    }
  }
  return filters;
};
