import { Reducer } from "./builder";
import { Option } from "functional-ts-primitives";

export interface UpdateDetailState {
  version: Option<string>;
  updateDate: Option<Date>;
  refreshDate: Option<Date>;
  scheduleIds: string[];
  refresh: boolean;
}

export const [ updateDetailReducer, updateDetailActions ] = Reducer
.withState<UpdateDetailState>({
  version: Option.none<string>(),
  updateDate: Option.none<Date>(),
  refreshDate: Option.none<Date>(),
  scheduleIds: [],
  refresh: false,
})
.withAction("clearUpdateDetail").map(
  (state) => ({
    version: Option.none<string>(),
    updateDate: Option.none<Date>(),
    refreshDate: Option.none<Date>(),
    scheduleIds: [],
    refresh: false,
  })
)
.withAction("setUpdateDetailVersion").merge<string>((state, version) => ({ version: Option.some(version) }))
.withAction("clearUpdateDetailVersion").merge((state) => ({ version: Option.none<string>() }))
.withAction("setUpdateDetailUpdateDate").merge<Date>((state, updateDate) => ({ updateDate: Option.some(updateDate) }))
.withAction("clearUpdateDetailUpdateDate").merge((state) => ({ updateDate: Option.none<Date>() }))
.withAction("setUpdateDetailRefreshDate").merge<Date>((state, refreshDate) => ({ refreshDate: Option.some(refreshDate) }))
.withAction("clearUpdateDetailRefreshDate").merge((state) => ({ refreshDate: Option.none<Date>() }))
.withAction("setUpdateDetailScheduleIds").merge<string[]>((state, scheduleIds) => ({ scheduleIds: scheduleIds }))
.withAction("clearUpdateDetailScheduleIds").merge((state) => ({ scheduleIds: [] }))
.withAction("setUpdateDetailRefresh").merge<boolean>((state, refresh) => ({ refresh: refresh }))
.build();