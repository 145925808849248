import { ScheduledUpdate } from "models";
interface ScheduleIdsWithStatus {
  scheduleId: string,
  status: string
}

export interface ScheduledUpdateResponse {
    updateToVersion: string;
    updateDate: Date;
    numberOfDatabases: number;
    numberOfCancelledUpdates: number;
    user: string;
    scheduleIdsWithStatus: ScheduleIdsWithStatus[]
}

export const toScheduledUpdates = (models: ScheduledUpdateResponse[]) : ScheduledUpdate[] =>
    models.map(model => toScheduledUpdate(model));

const toScheduledUpdate = (model: ScheduledUpdateResponse) : ScheduledUpdate => ({
    key: `${model.updateToVersion}-${model.updateDate}-${model.user}`,
    updateToVersion: model.updateToVersion,
    updateDate: model.updateDate,
    numberOfDatabases: model.numberOfDatabases,
    numberOfCancelledUpdates: model.numberOfCancelledUpdates,
    user: model.user,
    scheduleIds: model.scheduleIdsWithStatus.map(item => item.scheduleId)
});