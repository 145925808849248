import { Option, Result } from "functional-ts-primitives"
import { useEffect, useMemo, useState } from "react"
import { LazyResult, lazyResult } from "models/functional/LazyResult";

export const useResultEffectAsync = <TSuccess, TFailure>(effectAsync: (() => Promise<Result<TSuccess, TFailure>>), deps?: React.DependencyList) : [result: LazyResult<TSuccess, TFailure>, refresh: () => void] => {
    const [optionResult, setOptionResult] = useState<Option<Result<TSuccess, TFailure>>>(Option.none<Result<TSuccess, TFailure>>());
    const [ refresh, setRefresh ] = useState<boolean>(false);
    const refreshFunc = useMemo(() => () => setRefresh(r => !r), []);

    useEffect(() => {
        setOptionResult(Option.none());
        let isSubscribed = true;
        effectAsync()
            .then(result => isSubscribed ? setOptionResult(Option.some(result)) : {});
        return () => { isSubscribed = false; }
    }, [...(deps ?? []), refresh]);

    return [lazyResult(optionResult), refreshFunc];
}