import { Card, Typography } from "@iqmetrix/antd";
import { LazyResultMatch, ServiceAlert } from "components";
import { useResultEffectAsync } from "hooks";
import { VersionMappingsTable } from 'components/VersionMappingsTable';
import React from "react";
import { getVersionMappings } from "services";
import { Option } from "functional-ts-primitives";

const { Paragraph } = Typography;

export const VersionSelector : React.FC<{ onChange: (databaseVersion: string) => void, selected: Option<string> }> = (props) =>
{
    const [ versionMappingsResult, refreshVersionMappings ] = useResultEffectAsync(() => getVersionMappings());
    return <LazyResultMatch
                result={versionMappingsResult}
                success={versionMappings => 
                    <Card>
                        <React.Fragment>
                            <Paragraph>{versionMappings.length === 1 ? "1 version" : `${versionMappings.length} versions`}</Paragraph>
                            <VersionMappingsTable data={Option.some(versionMappings)} onChange={e => props.onChange(e)} selected={props.selected} scroll={({ y: "calc(100vh - 361px)" })}/>
                        </React.Fragment>
                    </Card>
                }
                failure={error =>
                    <ServiceAlert serviceError={error} type="error" ariaLabel="failed to retrieve version numbers alert" message="An error occurred when attempting to retrieve database update versions." onTryAgain={refreshVersionMappings}/>
                }
                loading={() =>
                  <Card>
                    <React.Fragment>
                    <Paragraph>0 versions</Paragraph>
                        <VersionMappingsTable data={Option.none()} onChange={e => props.onChange(e)} selected={props.selected} />
                    </React.Fragment>
                </Card>
                }/>;
}