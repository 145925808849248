import { Card, Typography } from '@iqmetrix/antd';
import React from 'react';
import { EditTagsTable } from 'components/EditTagsTable';
import { ManageDatabase } from 'models';

const { Paragraph } = Typography;

export const EditTagDatabasesCardInner : React.FC<{
  databases: ManageDatabase[]
}> = props => {
  return (
    <Card title="Databases">
      <Paragraph aria-label="database count">{props.databases.length} {props.databases.length === 1 ? "database" : "databases"}</Paragraph>
      <EditTagsTable
        databases={props.databases}
        scroll={{ y: "calc(100vh - 420px)" }}
      />
    </Card>
  );
};

export const EditTagDatabasesCard = React.memo(EditTagDatabasesCardInner);
