import { DatabaseTagsData } from "models";
import { addDatabaseTags, getAllTags, createTag as createTagCall, deleteTag as deleteTagCall, updateDatabaseTags as updateDatabaseTagsCall, updateTag as updateTagCall } from "services";
import { StoreType } from "store";
import { Guid } from "guid-typescript";
import { Result, ResultPromise, Unit } from "functional-ts-primitives";
import { ServiceError } from "errors";

export const populateTags = (store: StoreType) : ResultPromise<Unit, ServiceError> =>
  store.getState().tagsStore.tags.match(
    result => result.match(
      success => store.getState().tagsStore.expiryDate < new Date() ? populateTagsFromService(store) : Result.unitAsync(),
      failure => populateTagsFromService(store),
      Result.unitAsync),
    () => populateTagsFromService(store));

const populateTagsFromService = (store: StoreType) : ResultPromise<Unit, ServiceError> => {
  store.actions.setTagsLoading();
  return getAllTags().do(store.actions.setTags, store.actions.setTagsError).map(_ => Unit);
};

export const addTagsToDatabases = (store: StoreType, data: DatabaseTagsData[]) =>
  addDatabaseTags(data).doIfSuccessful(_ => store.actions.addTagsToDatabase(data));

export const createTag = (store: StoreType, tagName: string, companyIds: number[]) =>
{
  var tagId = Guid.create();
  return createTagCall(tagId, tagName, companyIds).doIfSuccessful(_ => store.actions.addTag({ key: tagId.toString(), tagName, companyIds }));
}

export const updateTag = (store: StoreType, tagId: string, tagName: string) =>
  updateTagCall(tagId, tagName).doIfSuccessful(_ => store.actions.updateTag({ tagId, tagName }));

export const deleteTag = (store: StoreType, tagId: string) =>
  deleteTagCall(tagId).doIfSuccessful(_ => store.actions.deleteTag(tagId));

export const updateDatabaseTags = (store: StoreType, data: DatabaseTagsData) =>
  updateDatabaseTagsCall(data).doIfSuccessful(_ => store.actions.updateDatabaseTags(data));