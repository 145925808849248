import { RestClientFactory } from 'functional-ts-rest';
import { ServiceError } from 'errors/index';
import { appInsights, trackServiceError } from "shared/appInsights";
import { Result } from 'functional-ts-primitives';
import config from './config';
import { msalInstance, redirectRequest } from './authConfig';
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export const ServiceClient =
    RestClientFactory
        .withBearerAsync(() =>
          msalInstance.acquireTokenSilent({
              redirectUri: config.silentRedirectUri,
              scopes: [ `${config.serviceRegistrationUri}/user_impersonation` ]
            })
            .catch(error => {
              if (error instanceof InteractionRequiredAuthError) {
                  msalInstance.acquireTokenRedirect(redirectRequest)
              }
              throw error;
            })
            .then(result => result.accessToken))
        .withFailureAsync<ServiceError>(error =>
            error
                .mapAsync(
                    response =>
                        Result
                            .tryAsync(() => response.json())
                            .match<ServiceError>(
                                properties => ({
                                    url: response.url,
                                    status: response.status,
                                    errorType: properties.errorType ?? response.statusText,
                                    message: properties.message ?? response.statusText,
                                    errorInformation: properties.errorInformation ?? []
                                }),
                                _ => ({
                                    url: response.url,
                                    status: response.status,
                                    errorType: response.statusText,
                                    message: response.statusText,
                                    errorInformation: []
                                })))
                .do(serviceError => trackServiceError(serviceError), error => appInsights.trackException({ exception: error }))
                .mapFailureAsync(
                    async error => ({
                        url: "N/A",
                        status: 500,
                        errorType: error.name,
                        message: error.message,
                        errorInformation: []
                    }))
                .match(error => error, error => error))
        .create();