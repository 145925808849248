import {ManageTag} from "models";

export interface ManageTagResponse {
    tagId: string;
    tagName: string;
    companyIds: number[]
}

export const toManageTags = (models: ManageTagResponse[]) : ManageTag[] =>
    models.map(model => toManageTag(model));

const toManageTag = (model: ManageTagResponse) : ManageTag => ({
    key: model.tagId,
    tagName: model.tagName,
    companyIds: model.companyIds
});