import React from 'react';
import { Card, DatePicker } from '@iqmetrix/antd';
import moment from 'moment';
import { useStoreSelector, useStoreDispatch } from 'store';
import { Option } from 'functional-ts-primitives';

export const UpdateDetailDateCard: React.FC = () => {
  const updateDate = useStoreSelector(state => state.updateDetail.updateDate);
  const { setCreateUpdateDate } = useStoreDispatch();
  return <Card title="Update date">
    <DatePicker
      format="MMM/DD/YYYY"
      placeholder="mmm/dd/yyyy"
      direction="ltr"
      disabledDate={current => current && current < moment().endOf('day')}
      aria-label="select update date"
      defaultValue={updateDate.map(date => moment(date).utc()).toNullable() ?? undefined} onChange={e => e?.date}
      onSelect={e => Option.fromNullable(e).applyIfSome(selected => setCreateUpdateDate(selected.toDate()))}
      allowClear={false}
      disabled />
  </Card>;
}