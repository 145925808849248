import React, { useEffect } from 'react';
import { compareVersionNumbers, ManageDatabase, toVersionNumberStringOption, VersionNumber } from 'models';
import { Badge, BadgeStyles, Table, Tooltip, Typography } from '@iqmetrix/antd';
import { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { Option } from 'functional-ts-primitives';
import { orderBy, uniqBy } from 'lodash';
import { useVT } from 'virtualizedtableforantd4';
import { useColumnSearchFilterProps } from 'hooks';
import styles from './ManageDatabaseSelector.module.scss'

const { Paragraph, Text } = Typography;

export const ManageDatabaseSelector : React.FC<{
  databases: Option<ManageDatabase[]>,
  selectedDatabases?: number[],
  onChange: (selectedDatabases: number[]) => void,
  scroll?: {
    x?: number | true | string;
    y?: number | string;
  }}> = props => {
  const [tags, setTags] = React.useState<({ text: string, value: string }[])>([]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<number[]>([]);
  const onChange = (rowKeys: number[]) => { setSelectedRowKeys(rowKeys); props.onChange(rowKeys); };
  const name = uniqBy(props.databases.valueOrEmpty().map(database => database.name).map(name => ({ text: name, value: name })), value => value.value);
  const servers = uniqBy(props.databases.valueOrEmpty().map(database => database.server).map(server => ({ text: server, value: server })), value => value.value);
  const currentVersion = uniqBy(props.databases.valueOrEmpty().map(database => database.currentVersion).map(currentVersion => ({ text: toVersionNumberStringOption(currentVersion).valueOrDefault(() => 'None'), value: currentVersion })), value => value.text);
  const serverColumnProps = useColumnSearchFilterProps('server', servers);
  const nameColumnProps = useColumnSearchFilterProps('name', name);
  const currentVersionColumnProps = useColumnSearchFilterProps('currentVersion', currentVersion);
  const rowSelection = {
    selectedRowKeys,
    onChange, 
  } as TableRowSelection<Record<string, any>>;

  useEffect(() => {
    setTags(uniqBy(props.databases.match(some => some.flatMap(database => database.tags).map(tag => ({ text: tag.tagName, value: tag.key })), () => []), value => value.value));
  }, [props.databases]);
  
  useEffect(() => {
    setSelectedRowKeys(props.selectedDatabases || selectedRowKeys);
  }, [props.selectedDatabases, selectedRowKeys]);

  const columns : ColumnsType<ManageDatabase> = [
    {
      title: 'Database name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (value, record) => <React.Fragment>{value} <Text className={styles.replicationText}>{record.replicationEnabled ? "(replication)" : ""}</Text></React.Fragment>,
      ...nameColumnProps
    },
    {
      title: 'Current version',
      dataIndex: 'currentVersion',
      render: (currentVersion: Option<VersionNumber>) => toVersionNumberStringOption(currentVersion).valueOrDefault(() => ''),
      sorter: (a, b) => compareVersionNumbers(a.currentVersion, b.currentVersion),
      ellipsis: true,
      ...currentVersionColumnProps,
      onFilter: (value: any, record: ManageDatabase) => compareVersionNumbers(record.currentVersion, value) === 0
    },
    {
      title: 'Server',
      dataIndex: 'server',
      sorter: (a, b) => a.server.localeCompare(b.server),
      ...serverColumnProps
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      render: (tags: { key: string, tagName: string }[]) => {
        let sortedTags = orderBy(tags, 'tagName', 'asc');
        return <React.Fragment>{sortedTags[0]?.tagName && <Badge count={sortedTags[0]?.tagName} style={BadgeStyles.Default} />} <Tooltip title={sortedTags.map(t => t.tagName).slice(1).join(', ')}>{sortedTags.length - 1 > 0 && <Badge count={`${sortedTags.length - 1} more`} style={BadgeStyles.Default} />}</Tooltip></React.Fragment>;
      },
      filters: orderBy(tags, 'text', 'asc'),
      onFilter: (value, record) => record.tags.flatMap(tag => tag.key).includes(value as string),
      filterMultiple: true
    }
  ];

  const [ virtualizeTable ] = useVT(() => ({ scroll: { y: 600 } }), []);
  return <React.Fragment>
      <Paragraph aria-label="database count">{selectedRowKeys.length} of {props.databases.valueOrEmpty().length} databases selected</Paragraph>
      <Table
        aria-label="list of databases"
        loading={!props.databases.hasValue()}
        dataSource={props.databases.valueOrEmpty()}
        columns={columns}
        pagination={false}
        rowSelection={rowSelection}
        rowKey={row => row.companyId}
        scroll={props.scroll}
        components={virtualizeTable}
        />
    </React.Fragment>;
}