import React from 'react';
import { Table, Badge, BadgeStyles, Tooltip } from '@iqmetrix/antd';
import { ManageDatabase } from '../../models/ManageDatabase';
import { ColumnsType } from 'antd/es/table/interface';
import { orderBy, uniqBy } from 'lodash';
import { compareVersionNumbers, toVersionNumberStringOption, VersionNumber } from 'models';
import { Option } from 'functional-ts-primitives';
import { useVT } from 'virtualizedtableforantd4';
import { useColumnSearchFilterProps } from 'hooks';

export const EditTagsTable: React.FC<{
  databases: ManageDatabase[];
  scroll?: {
    x?: number | true | string;
    y?: number | string;
  };
}> = (props) => {
  const [data, setData] = React.useState<ManageDatabase[]>([]);
    const [tags, setTags] = React.useState<({ text: string, value: string }[])>([]);
    const name = uniqBy(props.databases.map(database => database.name).map(name => ({ text: name, value: name })), value => value.value);
    const servers = uniqBy(props.databases.map(database => database.server).map(server => ({ text: server, value: server })), value => value.value);
    const currentVersion = uniqBy(props.databases.map(database => database.currentVersion).map(currentVersion => ({ text: toVersionNumberStringOption(currentVersion).valueOrDefault(() => 'None'), value: currentVersion })), value => value.text);
    const nameColumnProps = useColumnSearchFilterProps('name', name);
    const serversColumnProps = useColumnSearchFilterProps('server', servers);
    const currentVersionColumnProps = useColumnSearchFilterProps('currentVersion', currentVersion);
  React.useEffect(() => {
    setData(props.databases);
    setTags(uniqBy(props.databases.flatMap(database => database.tags).map(tag => ({ text: tag.tagName, value: tag.key })), value => value.value));
  }, [props.databases]);
  const columns: ColumnsType<ManageDatabase> = [
    {
      title: "Database name",
      dataIndex: "name",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...nameColumnProps,
      ellipsis: true,
    },
    {
      title: "Current version",
      dataIndex: "currentVersion",
      render: (currentVersion: Option<VersionNumber>) => toVersionNumberStringOption(currentVersion).valueOrDefault(() => ''),
      sorter: (a, b) => compareVersionNumbers(a.currentVersion, b.currentVersion),
      ellipsis: true,
      ...currentVersionColumnProps,
      onFilter: (value: any, record: ManageDatabase) => toVersionNumberStringOption(record.currentVersion).valueOrDefault(() => '').includes(value),
      
    },
    {
      title: "Server",
      dataIndex: "server",
      sorter: (a, b) => a.server.localeCompare(b.server),
      ...serversColumnProps,
      ellipsis: true,
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      render: (tags: { key: string, tagName: string }[]) => {
        let sortedTags = orderBy(tags, 'tagName', 'asc');
        return <React.Fragment>{sortedTags[0]?.tagName && <Badge count={sortedTags[0]?.tagName} style={BadgeStyles.Default} />} <Tooltip title={sortedTags.map(t => t.tagName).slice(1).join(', ')}>{sortedTags.length - 1 > 0 && <Badge count={`${sortedTags.length - 1} more`} style={BadgeStyles.Default} />}</Tooltip></React.Fragment>;
      },
      filters: orderBy(tags, 'text', 'asc'),
      onFilter: (value, record) => record.tags.flatMap(tag => tag.key).includes(value as string),
      filterMultiple: true
    },
  ];

  
  const [ virtualizeTable ] = useVT(() => ({ scroll: { y: 600 } }), []);
  return (
    <React.Fragment>
      <Table
        loading={!data}
        dataSource={data}
        columns={columns}
        pagination={false}
        scroll={props.scroll}
        components={virtualizeTable}
      />
    </React.Fragment>
  );
};
