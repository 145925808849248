import { UpdateDatabase, UpdateWindow } from "models";
import { Option } from "functional-ts-primitives";
import moment from 'moment';

export interface UpdateDatabaseResponse {
  companyId: number,
  databaseName: string
  serverName: string
  databaseVersion: string
  replicationEnabled: boolean
  updateWindow: UpdateWindowTimeRangeResponse | null
}

export interface UpdateWindowTimeRangeResponse {
  startTimeUtc: string
  endTimeUtc: string
}

export const toUpdateDatabases = (models: UpdateDatabaseResponse[]) : UpdateDatabase[] =>
  models.map(model => toUpdateDatabase(model));

const toUpdateDatabase = (model: UpdateDatabaseResponse) : UpdateDatabase => ({
  companyId: model.companyId,
  name: model.databaseName,
  server: model.serverName,
  currentVersion: model.databaseVersion,
  replicationEnabled: model.replicationEnabled,
  updateWindow: Option.fromNullable(model.updateWindow).map(window => toUpdateWindowTimeRange(window)),
  tags: []
});

const toUpdateWindowTimeRange = (model: UpdateWindowTimeRangeResponse) : UpdateWindow =>({
  startUtc: moment.utc(model.startTimeUtc, "hh:mm:ss").toDate(),
  endUtc: moment.utc(model.endTimeUtc, "hh:mm:ss").toDate()
});