import { Button } from '@iqmetrix/antd';
import styles from "./ExpandableList.module.scss";
import React, { useState } from 'react';

export const ExpandableList: React.FC<{
  items : string[],
  initialCount : number
}> = props => {
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const remainingCount = props.items.length - props.initialCount;
  const requiresExpanding = props.items.length > props.initialCount;
  const listItems = requiresExpanding 
    ? props.items.slice(0, isExpanded ? props.items.length : props.initialCount).map((item, index) => <li key={index}>{item}</li>)
    : props.items.map((item, index) => <li key={index}>{item}</li>);
  return (
    <React.Fragment>
      <ul className={styles.unorderedList}>{listItems}</ul>
      <Button type='text' className={styles.viewMoreButton} onClick={() => setExpanded(!isExpanded)} hidden={!requiresExpanding}>
        {isExpanded ? 'View less' : `View ${remainingCount} more`}
      </Button>
    </React.Fragment>
    );
};

