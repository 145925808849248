import { ServiceClient } from "shared/rest";
import {
  VersionMappingResponse,
  toVersionMappings,
  ScheduledUpdateResponse,
  toScheduledUpdates,
  UpdateDetailResponse,
  toUpdateDetails,
  UpdateDatabaseResponse,
  toUpdateDatabases,
  toCreateScheduledUpdateRequests,
  toCreateOnDemandUpdateRequests,
  BulkCancellationResponse,
  toScheduledUpdateCancellationFailedData,
  ManageDatabaseResponse,
  toManageDatabases,
  ManageTagResponse,
  toUpdateTagRequest,
  toManageTags,
  toCreateTagRequest,
  toAddTagsToDatabaseRequests,
  toGetBridgeScriptByStartAndStopVersionRequest,
  FileResponse
} from "./models";
import {
  VersionMapping,
  ScheduledUpdate,
  UpdateDetail,
  UpdateDatabase,
  CreateScheduledUpdateData,
  CreateOnDemandUpdateData,
  ScheduledUpdateCancellationFailedData,
  ManageDatabase,
  ManageTag,
  DatabaseTagsData,
  VersionNumber,
  toVersionNumber,
  toVersionNumberString
} from "models";
import { ResultPromise, Unit } from "functional-ts-primitives";
import { ServiceError } from "errors/index";
import config from "shared/config";
import { Moment } from "moment";
import { distinct } from "shared/arrayExtensions";
import { Guid } from "guid-typescript";
import { toCreateDefaultUpdateWindowRequest } from "./models/CreateDefaultUpdateWindowRequest";

const baseUrl = config.serviceUri;

export const getVersionMappings = (): ResultPromise<
  VersionMapping[],
  ServiceError
> =>
  ServiceClient.getAsync(`${baseUrl}/V1/VersionMappings`)
    .as<VersionMappingResponse[]>()
    .map<VersionMapping[]>((models) =>
      distinct(toVersionMappings(models), (model) => model.key)
    );

export const getDatabaseVersions = (): ResultPromise<
    VersionNumber[],
    ServiceError
  > =>
    ServiceClient.getAsync(`${baseUrl}/V1/VersionMappings`)
      .as<VersionMappingResponse[]>()
      .map<VersionNumber[]>((models) =>
        distinct(
          models
            .flatMap(model => toVersionNumber(model.minimumDatabaseVersion))
            .filter(versionOption => versionOption.hasValue())
            .flatMap(x => x.valueOrDefault(() => ({major:0, minor:0, build:0, revision:0}))),
          (versionNumber) => toVersionNumberString(versionNumber))        
      );

export const getScheduledUpdates = (
  startDate: Moment,
  endDate: Moment | null
): ResultPromise<ScheduledUpdate[], ServiceError> =>
  ServiceClient.getAsync(`${baseUrl}/V1/ScheduledUpdates/ByDateRange`)
    .withParameters({
      startDate: startDate.format("YYYY-MM-DDTHH:mm:ss"),
      endDate: endDate?.format("YYYY-MM-DDTHH:mm:ss"),
    })
    .as<ScheduledUpdateResponse[]>()
    .map<ScheduledUpdate[]>((models) => toScheduledUpdates(models));

export const getUpdateDatabasesPriorToVersion = (
  version: string
): ResultPromise<UpdateDatabase[], ServiceError> =>
  ServiceClient.getAsync(`${baseUrl}/V1/Databases/PriorToDatabaseVersion`)
    .withParameters({ versionNumber: version })
    .as<UpdateDatabaseResponse[]>()
    .map((models) => distinct(toUpdateDatabases(models), (model) => model.companyId));

export const getUpdateDetails = (
  scheduleIds: string[]
): ResultPromise<UpdateDetail[], ServiceError> =>
  ServiceClient.postAsync(`${baseUrl}/V1/ScheduledUpdates/ByScheduleIds`)
    .withJson(scheduleIds)
    .as<UpdateDetailResponse[]>()
    .map<UpdateDetail[]>((models) => toUpdateDetails(models));

export const bulkCreateScheduledUpdates = (
  scheduledUpdates: CreateScheduledUpdateData[]
): ResultPromise<Unit, ServiceError> =>
  ServiceClient.postAsync(`${baseUrl}/V1/ScheduledUpdates/Create/Bulk`)
    .withJson(toCreateScheduledUpdateRequests(scheduledUpdates))
    .asUnit();

export const bulkCreateOnDemandUpdates = (
  onDemandUpdates: CreateOnDemandUpdateData[]
): ResultPromise<Unit, ServiceError> =>
  ServiceClient.postAsync(`${baseUrl}/V1/ScheduledUpdates/Create/Bulk/Now`)
    .withJson(toCreateOnDemandUpdateRequests(onDemandUpdates))
    .asUnit();

export const cancelScheduledUpdates = (
  scheduleIds: string[]
): ResultPromise<ScheduledUpdateCancellationFailedData[], ServiceError> => {
  return ServiceClient.postAsync(`${baseUrl}/V1/ScheduledUpdates/Cancel/Bulk`)
    .withJson(scheduleIds)
    .as<BulkCancellationResponse>()
    .map<ScheduledUpdateCancellationFailedData[]>((response) =>
      toScheduledUpdateCancellationFailedData(
        response.failedCancellationRequests
      )
    );
};

export const getManageDatabases = (): ResultPromise<ManageDatabase[], ServiceError> =>
  ServiceClient.getAsync(`${baseUrl}/V1/Databases/Management`)
    .as<ManageDatabaseResponse[]>()
    .map<ManageDatabase[]>((models) => toManageDatabases(models))
    .map(databases => databases.filter(database => database.companyId > 0))
    .map(databases => distinct(databases, database => database.companyId));

export const getAllTags = (): ResultPromise<ManageTag[], ServiceError> =>
  ServiceClient.getAsync(`${baseUrl}/V1/Tags`)
    .as<ManageTagResponse[]>()
    .map<ManageTag[]>((models) => toManageTags(models));

export const deleteTag = (tagId: string): ResultPromise<Unit, ServiceError> =>
  ServiceClient.deleteAsync(`${baseUrl}/V1/Tags/${tagId}/Delete`)
    .asUnit();
    
export const updateTag = (
  id: string,
  name: string
): ResultPromise<Unit, ServiceError> =>
  ServiceClient.putAsync(`${baseUrl}/V1/tags/${id}/update`)
    .withJson(toUpdateTagRequest(name))
    .asUnit();

export const addDatabaseTags = (
  databaseTagsData: DatabaseTagsData[]
): ResultPromise<Unit, ServiceError> =>
  ServiceClient.postAsync(`${baseUrl}/V1/Databases/BulkAddTags`)
    .withJson(toAddTagsToDatabaseRequests(databaseTagsData))
    .asUnit();

export const updateDatabaseTags = (
  databaseTagsData: DatabaseTagsData
): ResultPromise<Unit, ServiceError> => {
  return ServiceClient.postAsync(`${baseUrl}/V1/Databases/${databaseTagsData.companyId}/ReplaceTags`)
    .withJson(databaseTagsData.tagIds)
    .asUnit();
};

export const createTag = (
  id: Guid,
  name: string,
  companyIds: number[]
): ResultPromise<Unit, ServiceError> =>
  ServiceClient.postAsync(`${baseUrl}/V1/Tags/Create`)
    .withJson(toCreateTagRequest(id, name, companyIds))
    .asUnit();

export const getBridgeScriptByStartAndStopVersions = (
  startVersion: string,
  stopVersion: string
): ResultPromise<FileResponse, ServiceError> =>
  ServiceClient.postAsync(`${baseUrl}/V1/Databases/GetBridgeScriptByStartAndStopVersion`)
    .withJson(toGetBridgeScriptByStartAndStopVersionRequest(startVersion, stopVersion))
    .asBlob()
    .map<FileResponse>(fileContent =>
      ({fileName: `${startVersion}-${stopVersion}.sql`, file: fileContent} as FileResponse));

export const setDefaultUpdateWindow = (
  companyId: number
): ResultPromise<Unit, ServiceError> =>
  ServiceClient.postAsync(`${baseUrl}/V1/UpdateWindow/CreateDefault`)
  .withJson(toCreateDefaultUpdateWindowRequest(companyId))
  .asUnit();