import React from 'react';
import { Card, Typography } from '@iqmetrix/antd';
import { OptionMatch } from "components";
import { Link } from 'react-router-dom';
import { routes } from 'shared/routes';
import { useStoreSelector } from 'store';

const { Paragraph } = Typography;

export const UpdateDetailVersionCard: React.FC = () => {
  const versionOption = useStoreSelector(state => state.updateDetail.version);
  return <Card title="Update to version">
    <OptionMatch
      option={versionOption}
      some={version => <Paragraph>{version}</Paragraph>}
      none={() => <Link to={routes.versionSelect.get()} aria-label="select update to version">Add version</Link>} />
  </Card>;
}