import { ServiceError } from 'errors';
import { useSelectorAsync } from 'hooks';
import { lazyLoading, LazyResult, lazyResultZip, ManageDatabase, ManageTag } from 'models';
import { createSelector } from 'reselect';
import { StoreState } from 'store';
import { populateTags } from 'composition';
import { Option } from 'functional-ts-primitives';
import { populateDatabases } from 'composition/commands/databases';

const tagsSelector = createSelector(
  (state: StoreState) => state.tagsStore.tags,
  (tags) => tags.match<LazyResult<ManageTag[], ServiceError>>(result => result, lazyLoading));

export const useTagsSelector = () => useSelectorAsync((store) => populateTags(store), tagsSelector);

const databasesAndTagsSelector = createSelector(
  (state: StoreState) => state.databasesStore.databases,
  (state: StoreState) => state.tagsStore.tags,
  (databases, tags) =>
    Option
      .zip(databases, tags)
      .toResult(() => new Error('No databases or tags have been loaded.'))
      .map(results => lazyResultZip(results[0], results[1]))
      .map(result => result.map(models => transformManageDatabasesToIncludeTags(models[0], models[1])))
      .match<LazyResult<ManageDatabase[], ServiceError>>(result => result, lazyLoading));

export const useDatabasesAndTagsSelector = () => useSelectorAsync(async (store) => {
  var results = await Promise.all([ populateDatabases(store), populateTags(store) ]);
  return results[0].bind(_ => results[1]);
}, databasesAndTagsSelector);

export const transformManageDatabasesToIncludeTags = (
  databases: ManageDatabase[],
  databaseTags: ManageTag[]
): ManageDatabase[] => {
  let tagsHash: Map<number, { key: string; tagName: string }[]> = getTagsHash(
    databaseTags
  );
  return databases.map((database) => ({
    ...database,
    tags: tagsHash.get(database.companyId) ?? [],
  }));
};

const getTagsHash = (
  tags: ManageTag[]
): Map<number, { key: string; tagName: string }[]> => {
  let tagsHash: Map<number, { key: string; tagName: string }[]> = new Map<
    number,
    { key: string; tagName: string }[]
  >();
  tags.forEach((tag) => {
    tag.companyIds.forEach((id) => {
      tagsHash.set(id, [
        ...(tagsHash.get(id) ?? []),
        { key: tag.key, tagName: tag.tagName },
      ]);
    });
  });
  return tagsHash;
};