import { Option } from 'functional-ts-primitives';

export interface VersionNumber {
    major: number,
    minor: number
    build: number,
    revision: number
}

let emptyVersionNumber: VersionNumber = {
  major: 0,
  minor: 0,
  build: 0,
  revision: 0
};

export const toVersionNumberString = (versionNumber: VersionNumber) : string => 
  `${versionNumber.major}.${versionNumber.minor}.${versionNumber.build}.${versionNumber.revision}`;

export const toVersionNumberStringOption = (version: Option<VersionNumber>) : Option<string> => 
version.match(
  versionNumber => Option.some(toVersionNumberString(versionNumber)),
  () => Option.none<string>());

export const compareVersionNumbers = (version1 : Option<VersionNumber>, version2: Option<VersionNumber>) : number => {  
  let versionNumber1 = version1.valueOrDefault(() => emptyVersionNumber);
  let versionNumber2 = version2.valueOrDefault(() => emptyVersionNumber);

  if (versionNumber1.major === versionNumber2.major && versionNumber1.minor === versionNumber2.minor && versionNumber1.build === versionNumber2.build && versionNumber1.revision === versionNumber2.revision)
    return 0;
  else if ((versionNumber1.major < versionNumber2.major)
    || (versionNumber1.major === versionNumber2.major && versionNumber1.minor < versionNumber2.minor)
    || (versionNumber1.major === versionNumber2.major && versionNumber1.minor === versionNumber2.minor && versionNumber1.build < versionNumber2.build)
    || (versionNumber1.major === versionNumber2.major && versionNumber1.minor === versionNumber2.minor && versionNumber1.build === versionNumber2.build && versionNumber1.revision < versionNumber2.revision))
    return -1;
  else
    return 1;
}

export const toVersionNumber = (versionNumberString: string|null) : Option<VersionNumber> => {
  var versionFormatRegex = /^([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)$/g;
  if(!versionNumberString || !versionNumberString.match(versionFormatRegex)){
    return Option.none<VersionNumber>();
  }

  let versionComponents = versionNumberString.split('.');
  let major = parseInt(versionComponents[0]);
  let minor = parseInt(versionComponents[1]);
  let build = parseInt(versionComponents[2]);
  let revision = parseInt(versionComponents[3]);

  return Number.isInteger(major) && Number.isInteger(minor) && Number.isInteger(build) && Number.isInteger(revision) ? Option.some({major, minor, build, revision}) : Option.none<VersionNumber>();
};