import React from 'react';
import { Button, PageHeader  } from '@iqmetrix/antd';
import { useRouteMatch } from 'react-router-dom';
import { getBreadcrumbs } from 'shared/breadcrumbs';
import config from "shared/config";
import styles from "./AppPageHeader.module.scss";

export const AppPageHeader : React.FC<{ title: string, extra?: React.ReactNode }> = props => {
    const match = useRouteMatch<{ [key: string] : any }>();
    return (
    <PageHeader title={props.title} breadcrumb={getBreadcrumbs(match)} extra={props.extra}>
        <Button type="link" size="small" onClick={() => window.location.href = config.landingAppUri} className={styles.environmentButton} aria-label="select environment link">
            {config.environmentLabel} environment
        </Button>
    </PageHeader>);
}