import { Skeleton } from "@iqmetrix/antd";
import { PageLayout } from "@iqmetrix/layout";
import React from "react";
import { AppPageHeader } from 'components/AppPageHeader';

export const LoadingPage : React.FC = () =>
<PageLayout size="full">
{{
    header: <AppPageHeader title="Scheduled updates"></AppPageHeader>,
    content: [
        {
            primary: <Skeleton  loading active />
        }
    ]
}}
</PageLayout>;