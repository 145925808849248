import React from 'react';
import { Alert, Button, Space } from '@iqmetrix/antd';
import { AlertProps } from "antd/lib/alert";
import { ServiceError } from "./../../errors/ServiceError";

export interface ServiceAlertProps extends AlertProps {
  serviceError: ServiceError;
  onTryAgain?: () => void;
  ariaLabel?: string;
}

export const ServiceAlert: React.FC<ServiceAlertProps> = props => {
  const [toggleErrorDetails, setToggleErrorDetails] = React.useState<boolean>(false);
  const onClickToggleErrorDetails = () => setToggleErrorDetails(!toggleErrorDetails);
  console.log(props.serviceError.errorType)
  if(props.serviceError.errorType === "MissingAdminPermissionsError")
    return <React.Fragment>
      <Alert
        type="error"
        aria-label={props.ariaLabel}
        closable={props.closable}
        onClose={props.onClose}
        message="Please contact the business solutions team to request permission to access this application."/>
    </React.Fragment>
  else
    return <React.Fragment>
      <Alert
        type={props.type}
        aria-label={props.ariaLabel}
        closable={props.closable}
        onClose={props.onClose}
        message={
          <React.Fragment>
            <Space direction="horizontal">
              {props.message}
              {props.onTryAgain && <Button type="link" onClick={props.onTryAgain}>Try again</Button>}
            </Space>
            <div>
              <Space direction="vertical">
                {
                  toggleErrorDetails && <div style={{ paddingTop: 10 }}>
                    <div>Status code: {props.serviceError.status}</div>
                    <div>Error type: {props.serviceError.errorType}</div>
                    <div>Message: {props.serviceError.message}</div>
                    <div>Info: </div>
                    <div>{getInfoList(props.serviceError.errorInformation)}</div>
                    <div>Url: {props.serviceError.url}</div>
                  </div>
                }
                <Button type="link" onClick={onClickToggleErrorDetails}>{!toggleErrorDetails ? 'Show' : 'Hide'} error details</Button>
              </Space>
            </div>
          </React.Fragment>
        } />
    </React.Fragment>
}

function getInfoList(errorInformation: { type: string; information: string; }[]): React.ReactFragment {
  return <React.Fragment>
    <ul>
      {errorInformation.map(tuple => <li>{`${tuple.type}: ${tuple.information}`}</li>)}
    </ul>
  </React.Fragment>
}
