import {Modal} from '@iqmetrix/antd';
import { ReactNode } from 'react';

export interface ConfirmationModalOptions {
  title: string;
  content: ReactNode,
  okText: string;
  cancelText?: string;
  onOk(): void;
  onCancel(): void;
};

export const showConfirmationModal = (modalOptions: ConfirmationModalOptions) => {
  return Modal.confirm({
    title: modalOptions.title,
    content: modalOptions.content,
    width: 436,
    bodyStyle: {maxHeight: 450},
    centered: true,
    okText: modalOptions.okText,
    okButtonProps: {
      "aria-label": "modal ok button"
    },
    cancelText: "Cancel" && modalOptions.cancelText,
    cancelButtonProps: {
      type: 'default',
      "aria-label": "modal cancel button"
    },
    onOk:modalOptions.onOk,
    onCancel: modalOptions.onCancel
  });
}