import React, { ReactNode, useState } from 'react';
import { Button, Col, Row, message } from '@iqmetrix/antd';
import { PageLayout } from '@iqmetrix/layout';
import { AppPageHeader, ServiceAlert } from "components";
import { Link, useHistory } from 'react-router-dom';
import { routes } from 'shared/routes';
import { useStoreDispatch, useStoreSelector } from 'store';
import { UpdateDateCard } from './UpdateDateCard';
import { UpdateVersionCard } from './UpdateVersionCard';
import { UpdateDatabasesCard } from "./UpdateDatabasesCard";
import { Option, ResultPromise, Unit } from "functional-ts-primitives";
import { bulkCreateScheduledUpdates, bulkCreateOnDemandUpdates } from "services";
import { ServiceError } from 'errors';
import { showConfirmationModal, ConfirmationModalOptions } from 'shared/confirmationModals';
import { SelectedDatabase } from 'models/SelectedDatabase';
import { ExpandableList } from 'components/ExpandableList';

export const CreateUpdatePage: React.FC = () => {
  const { clearCreateUpdate } = useStoreDispatch();
  const updateData = useStoreSelector(state =>
    Option.zip(
      state.createUpdate.version,
      Option.some(state.createUpdate.updateNow),
      state.createUpdate.updateNow ? Option.some(new Date()) : state.createUpdate.updateDate,
      Option.create(() => state.createUpdate.selectedDatabases.length > 0, () => state.createUpdate.selectedDatabases)
    )
  );
  
  const history = useHistory();
  const onCancel = () => {
    clearCreateUpdate();
    history.replace(routes.scheduledUpdates.get());
  };

  const enableSave = updateData.hasValue();
  const [isSaving, setIsSaving] = useState<boolean>();
  const [saveError, setSaveError] = useState<Option<ServiceError>>(Option.none());
  const executeSave = () => updateData.applyIfSome(data => {

    setIsSaving(true);
    
    return callServiceToCreateUpdates(data)
      .doIfFailure(error => setSaveError(Option.some(error)))
      .doAlways(() => setIsSaving(false))
      .doIfSuccessful(() => {
        message.success("Changes saved.");
        history.replace(routes.scheduledUpdates.get());
      });
  });

  const callServiceToCreateUpdates = (data: [string, boolean, Date, SelectedDatabase[]]): ResultPromise<Unit, ServiceError> => {
    const [version, updateOnDemand, dateUtc, selectedDatabases] = data;
    return updateOnDemand
      ? bulkCreateOnDemandUpdates(selectedDatabases.map(selectedDatabase => ({ selectedDatabase, version })))
      : bulkCreateScheduledUpdates(selectedDatabases.map(selectedDatabase => ({ selectedDatabase, version, dateUtc })))
  }

  const getConfirmationDialogOptions = (updateData : [string, boolean, Date, SelectedDatabase[]]) : ConfirmationModalOptions => { 
    const [, onDemand, , selectedDatabases] = updateData; 
    
    let updateDatabaseNames = selectedDatabases.map(database => `${database.name} ${database.replicationEnabled ? '(replication)' : ''}`).sort();
    let updateCount = selectedDatabases.length;
    let replicationCount = selectedDatabases.filter(database => database.replicationEnabled).length;

    let updateContent =  
      onDemand  ? <div style={{maxHeight: 450, overflowX: "hidden", overflowY: "auto"}}>
                    <p>You are about to update {updateCount} databases. This includes {replicationCount} replication databases.</p>
                    <p>Starting an update now will override the "preferred window" of time a client has specified. It may cause service disruptions.</p>
                    The databases being updated are:
                    {getUpdateDatabaseNameList(updateDatabaseNames)}
                  </div>
                : <div style={{maxHeight: 450, overflowX: "hidden", overflowY: "auto"}}>
                    <p>You are about to schedule an update for {updateCount} databases. This includes {replicationCount} replication databases.</p>
                    The databases being updated are:
                    {getUpdateDatabaseNameList(updateDatabaseNames)}
                  </div>;

    return {
      title: onDemand ? 'Update now?' : 'Schedule database updates',
      content: updateContent,
      okText: onDemand ? 'Start update' : 'Schedule update',
      cancelText: 'Cancel',
      onOk: () => executeSave(),
      onCancel: () => { }
    };
  }

  const getUpdateDatabaseNameList = (names : string[]) : ReactNode => {
    return <ExpandableList items={names} initialCount={10} />
  }

  return <PageLayout size="full">
    {{
      header:
        <AppPageHeader
          title="Create Update"
          extra={
            <React.Fragment>
              <Link to={routes.scheduledUpdates.get()}>
                <Button type="default" onClick={onCancel} aria-label="cancel button" disabled={isSaving}>Cancel</Button>
              </Link>
              <Button type="primary" aria-label="save button" disabled={!enableSave} loading={isSaving} onClick={() => updateData.applyIfSome(data => showConfirmationModal(getConfirmationDialogOptions(data)))}>
                Save
              </Button>
            </React.Fragment>
          } />,
      messages: saveError.match(
        error => <ServiceAlert serviceError={error} type="error" message="An error occurred when attempting to schedule your request." closable={true} onClose={() => setSaveError(Option.none())} ariaLabel="An error occurred on save alert" />,
        () => null),
      content: [
        {
          primary:
            <Row gutter={[14, 17]} aria-label="create update page">
              <Col span={18}>
                <UpdateDatabasesCard />
              </Col>
              <Col span={6}>
                <Row gutter={[14, 17]}>
                  <Col span={24}>
                    <UpdateVersionCard />
                  </Col>
                  <Col span={24}>
                    <UpdateDateCard />
                  </Col>
                </Row>
              </Col>
            </Row>
        }
      ]
    }}
  </PageLayout>;
}