import React, { useEffect } from 'react';
import { compareVersionNumbers, UpdateDatabase, toUpdateWindowLocalTimesString, toVersionNumber, UpdateWindow, compareUpdateWindowOptions, ManageTag } from 'models';
import { Table, Tooltip, Typography, Badge, BadgeStyles, Button, Modal } from '@iqmetrix/antd';
import { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { Option } from 'functional-ts-primitives';
import { OptionMatch } from 'components';
import { uniqBy, orderBy } from "lodash";
import styles from "./DatabaseSelector.module.scss";
import { useVT } from 'virtualizedtableforantd4';
import { useColumnSearchFilterProps } from 'hooks';
import { SelectedDatabase } from 'models';
import { setDefaultUpdateWindow } from 'services';
import { ServiceError } from 'errors';

const { Paragraph, Text } = Typography;

export const DatabaseSelector: React.FC<{
  databases: Option<UpdateDatabase[]>,
  tags: Option<ManageTag[]>,
  selectedDatabases?: number[],
  onChange: (selectedDatabases: SelectedDatabase[]) => void,
  onRequestReload: () => void,
  scroll?: {
    x?: number | true | string;
    y?: number | string;
  }
}> = props => {
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<number[]>([]);
  const onChange = (rowKeys: number[]) => { setSelectedRowKeys(rowKeys); props.onChange(getSelectedUpdateDatabasesFromRowKeys(rowKeys, props.databases.valueOrDefault(() => []))); };
  const getSelectedUpdateDatabasesFromRowKeys = (rowKeys: number[], databaseList: UpdateDatabase[]) : SelectedDatabase[] => {
      return databaseList
                  .filter(database => rowKeys.includes(database.companyId))
                  .map(database => ({companyId: database.companyId, name: database.name , replicationEnabled: database.replicationEnabled}) as SelectedDatabase) 
  }
  
  const [tags, setTags] = React.useState<({ text: string, value: string }[])>([]);
  const [viewSelected, setViewSelected] = React.useState<boolean>(false);
  const currentVersion = uniqBy(props.databases.valueOrEmpty().map(database => database.currentVersion).map(currentVersion => ({ text: currentVersion, value: currentVersion })), value => value.text);
  const server = uniqBy(props.databases.valueOrEmpty().map(database => database.server).map(server => ({ text: server, value: server })), value => value.value);
  const name = uniqBy(props.databases.valueOrEmpty().map(database => database.name).map(name => ({ text: name, value: name })), value => value.value);
  const serverColumnProps = useColumnSearchFilterProps('server', server);
  const currentVersionColumnProps = useColumnSearchFilterProps('currentVersion', currentVersion);
  const nameColumnProps = useColumnSearchFilterProps('name', name);
  const rowSelection = {
    selectedRowKeys,
    onChange,
    getCheckboxProps: record => ({ disabled: !databaseRecordIsEnabled(record) })
  } as TableRowSelection<Record<string, any>>;

  useEffect(() => {
    setSelectedRowKeys(props.selectedDatabases || selectedRowKeys);
  }, [props.selectedDatabases, selectedRowKeys]);

  React.useEffect(() => {
    setTags(uniqBy(props.databases.valueOrEmpty().flatMap(database => database.tags).map(tag => ({ text: tag.tagName, value: tag.key })), value => value.value));
  }, [props.databases]);

  const databaseRecordIsEnabled = (record: any): boolean => {
    return (record.updateWindow as Option<UpdateWindow>).hasValue();
  }

  const onUpdateWindowNotSetClick = (record: UpdateDatabase): void => {
    setDefaultUpdateWindow(record.companyId)
            .do(_ => props.onRequestReload(), _ => {})
            .applyIfFailure(error => showUpdateWindowFailureModel(record, error));     
  }

  const showUpdateWindowFailureModel = (record: UpdateDatabase, error: ServiceError) => {
    let content =  <React.Fragment>
                      <p>Failure to set default window for database {record.name}.</p>
                      <p>StatusCode: {error.status}</p>
                      <p>ErrorType: {error.errorType}</p>
                      <p>Message: {error.message}</p>
                    </React.Fragment>;
    return Modal.error({
      title: "Error",
      content: content,
      width: 436,
      bodyStyle: {maxHeight: 450},
      centered: true,
      okText: "Ok",
      okButtonProps: {
        "aria-label": "modal ok button"
      },
      onOk: () => {},
    });
  }

  const columns: ColumnsType<UpdateDatabase> = [
    {
      title: 'Database name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 1
      },
     ...nameColumnProps,
     render: (value, record) => <React.Fragment>{value} <Text className={styles.replicationText}>{record.replicationEnabled ? "(replication)" : ""}</Text></React.Fragment>
    },
    {
      title: 'Current version',
      dataIndex: 'currentVersion',
      sorter: (a, b) => compareVersionNumbers(toVersionNumber(a.currentVersion), toVersionNumber(b.currentVersion)),
     ...currentVersionColumnProps
    },
    {
      title: 'Server',
      dataIndex: 'server',
      sorter: (a, b) => a.server.localeCompare(b.server),
      ...serverColumnProps
    },
    {
      title: <Tooltip title="Refers to database preferred update window and showing logged in users timezone.">Preferred window</Tooltip>,
      dataIndex: 'updateWindow',
      defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b) => compareUpdateWindowOptions(a.updateWindow, b.updateWindow),
        multiple: 2
        },
      showSorterTooltip: false,
      render: (windowOption: Option<UpdateWindow>, record: UpdateDatabase) =>
        <OptionMatch
          option={windowOption}
          some={window => <React.Fragment>{toUpdateWindowLocalTimesString(window)}</React.Fragment>}
          none={() => <React.Fragment><Button data-testid={record.companyId} style={{ wordWrap: 'break-word', wordBreak: 'break-word' }} type="link" onClick={() => onUpdateWindowNotSetClick(record)}><Tooltip title="Click to set default environmental Update Window.">Not set</Tooltip></Button></React.Fragment>} />
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      render: (tags: { key: string, tagName: string }[]) => {
        let sortedTags = orderBy(tags, 'tagName', 'asc');
        return <React.Fragment>{sortedTags[0]?.tagName && <Badge count={sortedTags[0]?.tagName} style={BadgeStyles.Default} />} <Tooltip title={sortedTags.map(t => t.tagName).slice(1).join(', ')}>{sortedTags.length - 1 > 0 && <Badge count={`${sortedTags.length - 1} more`} style={BadgeStyles.Default} />}</Tooltip></React.Fragment>;
      },
      filters: orderBy(tags, 'text', 'asc'),
      onFilter: (value, record) => record.tags.flatMap(tag => tag.key).includes(value as string),
      filterMultiple: true
    },
  ];

  const [virtualizeTable] = useVT(() => ({ scroll: { y: 600 } }), []);
  return <React.Fragment>
    <div className={styles.actionBar}>
      <Paragraph>{selectedRowKeys.length} of {props.databases.valueOrEmpty().length} databases selected      
        <Button type='text' className={styles.viewSelectedButton} onClick={() => setViewSelected(!viewSelected)} hidden={selectedRowKeys.length === 0}>
          {!viewSelected ? 'View selected' : 'View all'}
        </Button>
      </Paragraph>
    </div>
    <Table<UpdateDatabase>
      aria-label="list of databases"
      loading={!props.databases.hasValue()}
      dataSource={props.databases.valueOrEmpty().filter(database => viewSelected ? selectedRowKeys.includes(database.companyId) : true)}
      columns={columns}
      pagination={false}
      rowSelection={rowSelection}
      rowKey={row => row.companyId}
      rowClassName={row => !row.updateWindow.hasValue() ? "disabled" : ""}
      scroll={props.scroll}
      components={virtualizeTable}
    />
  </React.Fragment>;
}