import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import config from "shared/config";
import { ServiceError } from 'errors';

const browserHistory = createBrowserHistory({ basename: '' });
const appInsightsReactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: config.appInsights.instrumentationKey,
        extensions: [appInsightsReactPlugin],
        extensionConfig: {
          [appInsightsReactPlugin.identifier]: { history: browserHistory }
        },
        loggingLevelTelemetry: config.appInsights.enabled ? 0 : undefined
    }
});
appInsights.loadAppInsights();
export { appInsightsReactPlugin, appInsights };

export const trackServiceError = (error: ServiceError) =>
  appInsights.trackException({
    exception: new Error(error.message),
    properties: {
      errorType: error.errorType,
      requestUrl: error.url,
      responseStatus: error.status,
      errorMessage: error.message,
      ...error.errorInformation.reduce((item, info) => ({ ...item, [info.type]: info.information }), {})
    }
  });