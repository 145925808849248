export const routes = {
  scheduledUpdates: {
    path: "/",
    breadcrumb: (params: any) => "Scheduled updates",
    get: () => routes.scheduledUpdates.path,
  },
  createUpdate: {
    path: "/create",
    breadcrumb: (params: any) => "Create update",
    get: () => routes.createUpdate.path,
  },
  versionSelect: {
    path: "/create/selectVersion",
    breadcrumb: (params: any) => "Add version",
    get: () => routes.versionSelect.path,
  },
  updateDetailPage: {
    path: "/:dbversion",
    breadcrumb: (params: any) => params.dbversion,
    get: (dbversion: string) =>
      routes.updateDetailPage.path.replace(":dbversion", dbversion),
  },
  manageDatabases: {
    path: "/manageDatabases",
    breadcrumb: (params: any) => "Manage databases",
    get: () => routes.manageDatabases.path,
  },
  editTag: {
    path: "/manageDatabases/tags/:tagid",
    breadcrumb: (params: any) => "Edit Tag",
    get: (tagid: string) => routes.editTag.path.replace(":tagid", tagid),
  },
  createTag: {
    path: "/manageDatabases/createTag",
    breadcrumb: (params: any) => "Create Tag",
    get: () => routes.createTag.path
  }
};
