import React from 'react';
import { Card } from '@iqmetrix/antd';
import { useStoreSelector } from 'store';
import { useResultEffectAsync } from "hooks";
import { getUpdateDetails } from "services";
import { LazyResultMatch, ServiceAlert } from "components";
import { UpdateDetailDatabasesTable } from 'components/UpdateDetailDatabasesTable';
import { Option } from "functional-ts-primitives";

export const UpdateDetailDatabasesCard: React.FC = () => {
    const scheduleIds = useStoreSelector(state => state.updateDetail.scheduleIds);
    const refresh = useStoreSelector(state => state.updateDetail.refresh);
    const [updateDetailDatabasesResult, getUpdateDetailDatabases] = useResultEffectAsync(() => getUpdateDetails(scheduleIds), [refresh]);
    return <Card title="Databases">
        <LazyResultMatch
            result={updateDetailDatabasesResult}
            success={updateDetailDatabases => <UpdateDetailDatabasesTable data={Option.some(updateDetailDatabases)} scroll={({ y: "calc(100vh - 481px)" })} />}
            failure={error =>
                <ServiceAlert serviceError={error} type="error" message="An error occurred when attempting to retrieve scheduled database updates." onTryAgain= {getUpdateDetailDatabases} />
            }
            loading={() => <UpdateDetailDatabasesTable data={Option.none()} scroll={({ y: "calc(100vh - 481px)" })} />} />
    </Card>
}