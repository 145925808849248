import { Reducer } from "./builder";
import { Option } from "functional-ts-primitives";
import { DatabaseTagsData, lazyFailure, lazyLoading, LazyResult, lazySuccess, ManageTag } from "models";
import { ServiceError } from "errors";
import config from "shared/config";

export interface TagsStoreState {
  tags: Option<LazyResult<ManageTag[], ServiceError>>;
  expiryDate: Date;
}

export const [tagsStoreReducer, tagStoreActions] = Reducer.withState<TagsStoreState>(
{
  tags: Option.none(),
  expiryDate: new Date()
})
.withAction("setTagsLoading")
.map((state) => ({
  tags: Option.some(lazyLoading()),
  expiryDate: new Date()
}))
.withAction("setTags")
.map<ManageTag[]>((state, tags) => ({
  tags: Option.some(lazySuccess(tags)),
  expiryDate: getNewExpiryDate()
}))
.withAction("setTagsError")
.map<ServiceError>((state, error) => ({
  tags: Option.some(lazyFailure(error)),
  expiryDate: new Date()
}))
.withAction("addTagsToDatabase")
.merge<DatabaseTagsData[]>((state, data) => ({
  tags: state.tags.map(result => result.map(tags => 
    tags.map(tag => ({
      ...tag,
      companyIds: [ ...tag.companyIds, ...data.filter(x => x.tagIds.includes(tag.key) && !tag.companyIds.includes(x.companyId)).map(x => x.companyId) ]
    }))))
}))
.withAction("addTag")
.merge<ManageTag>((state, tag) => ({
  tags: state.tags.map(result => result.map(tags => [ ...tags, tag ]))
}))
.withAction("updateTag")
.merge<{ tagId: string, tagName: string}>((state, data) => ({
  tags: state.tags.map(result => result.map(tags => tags.map(tag => tag.key === data.tagId ? { ...tag, tagName: data.tagName } : tag)))
}))
.withAction("deleteTag")
.merge<string>((state, tagId) => ({
  tags: state.tags.map(result => result.map(tags => tags.filter(tag => tag.key !== tagId)))
}))
.withAction("updateDatabaseTags")
.merge<DatabaseTagsData>((state, data) => ({
  tags: state.tags.map(result => result.map(tags => tags.map(tag => ({
    ...tag,
    companyIds: [
      ...tag.companyIds.filter(id => id !== data.companyId),
      ...(data.tagIds.includes(tag.key) ? [ data.companyId ] : [])
    ]
  }))))
}))
.build();

const getNewExpiryDate = () =>
{
  var expiryDate = new Date();
  expiryDate.setMinutes(expiryDate.getMinutes() + config.store.cacheExpiryInMinutes);
  return expiryDate;
}