import React, { useMemo } from "react";
import { LazyResult } from "models/functional/LazyResult";

export interface LazyResultMatchProps<TSuccess, TFailure> {
    result: LazyResult<TSuccess, TFailure>
    success: (success: TSuccess) => React.ReactElement
    failure: (failure: TFailure) => React.ReactElement
    loading: () => React.ReactElement
}

export const LazyResultMatch = <TSuccess, TFailure>(props: LazyResultMatchProps<TSuccess, TFailure>) =>
    props.result.match(
        successValue => props.success(successValue),
        failureValue => props.failure(failureValue),
        () => props.loading()
    );

export const LazyResultMatchMemo = <TSuccess, TFailure>(
  props: LazyResultMatchProps<TSuccess, TFailure> & { deps: any[] },
  ) => useMemo(() =>  LazyResultMatch(props), [ props.result, ...props.deps]);