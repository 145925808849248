import React from 'react';
import { Button, Col, Row } from '@iqmetrix/antd';
import { PageLayout } from '@iqmetrix/layout';
import { AppPageHeader } from "components";
import { useHistory } from 'react-router-dom';
import { routes } from 'shared/routes';
import { useStoreDispatch, useStoreSelector } from 'store';
import { UpdateDetailDatabasesCard, UpdateDetailVersionCard, UpdateDetailDateCard } from './index';
import moment from 'moment';

export const UpdateDetailPage: React.FC = () => {

  const { setUpdateDetailRefresh, setUpdateDetailRefreshDate } = useStoreDispatch();

  const history = useHistory();
  const selectedVersionOption = useStoreSelector(state => state.updateDetail.version);
  if (!selectedVersionOption.hasValue()) {
    history.push(routes.scheduledUpdates.get());
  }

  const refresh = useStoreSelector(state => state.updateDetail.refresh);
  const refreshDateOption = useStoreSelector(state => state.updateDetail.refreshDate);

  const onRefresh = () => {
    setUpdateDetailRefresh(!refresh);
    setUpdateDetailRefreshDate(new Date());
  }

  return <PageLayout size="full">
    {{
      header:
        <AppPageHeader
          title={selectedVersionOption.match(version => version, () => '')} />,
      content: [
        {
          primary:
            <React.Fragment>
              <Row gutter={[14, 17]}>
                <Col span={24}>
                  <Button type="link" aria-label="refresh button" onClick={onRefresh}>Refresh</Button> {refreshDateOption.match(refreshDate => `Updated ${moment(refreshDate).format('MMMM DD, YYYY')} at ${moment(refreshDate).format('hh:mm a')}`, () => '')}
                </Col>
              </Row>
              <Row gutter={[14, 17]} aria-label="update detail page">
                <Col span={18}>
                  <UpdateDetailDatabasesCard />
                </Col>
                <Col span={6}>
                  <Row gutter={[14, 17]}>
                    <Col span={24}>
                      <UpdateDetailVersionCard />
                    </Col>
                    <Col span={24}>
                      <UpdateDetailDateCard />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </React.Fragment>
        }
      ]
    }}
  </PageLayout>;
}