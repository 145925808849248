export const distinct = <T, TKey>(array: Array<T>, keyFactory: (item: T) => TKey) =>
{
  var newArray = []as Array<T>;
  var keys = []as Array<TKey>;
  array.forEach(item =>
  {
    var key = keyFactory(item);
    if(keys.indexOf(key) === -1)
    {
      newArray.push(item);
      keys.push(key);
    }
  });
  return newArray;
}