import React from 'react';
import { Card, Typography } from '@iqmetrix/antd';
import { OptionMatch } from "components";
import { Link } from 'react-router-dom';
import { routes } from 'shared/routes';
import { useStoreSelector } from 'store';

const { Paragraph } = Typography;

export const UpdateVersionCard : React.FC = () =>
{
    const versionOption = useStoreSelector(state => state.createUpdate.version);
    return  <Card title="Update to version" extra={versionOption.match(_ => <Link to={routes.versionSelect.get()} aria-label="change update version">Change</Link>, () => null)}>
                <OptionMatch 
                    option={versionOption}
                    some={version => <Paragraph>{version}</Paragraph>}
                    none={() => <Link to={routes.versionSelect.get()} aria-label="select update to version">Add version</Link>}/>
            </Card>;
}