import React from 'react';
import { Table, Modal, Button } from '@iqmetrix/antd';
import { ColumnsType } from 'antd/es/table';
import { Option } from 'functional-ts-primitives';
import { ManageTag } from '../../models/ManageTag';
import styles from "./ManageTagsTable.module.scss";
import { useStore, useStoreDispatch } from 'store';
import { Link } from 'react-router-dom';
import { routes } from 'shared/routes';
import { ManageDatabase } from 'models';
import { deleteTag } from 'composition';
import { useColumnSearchFilterProps } from 'hooks';
import { useVT } from 'virtualizedtableforantd4';
import { uniqBy } from 'lodash';


export const ManageTagsTable: React.FC<{
  tags: Option<ManageTag[]>,
  databases: Option<ManageDatabase[]>,
  onDeleteTagsError: (errorMessage: Option<string>) => void
  scroll?: {
    x?: number | true | string;
    y?: number | string;
  }
}> = props => {
  const [data, setData] = React.useState<ManageTag[]>([]);
  const [databases, setDatabases] = React.useState<ManageDatabase[]>([]);
  const [isDeleteTagsModalVisible, setIsDeleteTagsModalVisible] = React.useState<boolean>(false);
  const [selectedTagForDeletion, setSelectedTagForDeletion] = React.useState<Option<ManageTag>>(Option.none);
  const { setEditTagId, setEditTagName, setEditTagDatabases } = useStoreDispatch();
  const tagName = uniqBy(props.tags.valueOrEmpty().map(mapping => mapping.tagName).map(tagName => ({ text: tagName.toString(), value: tagName })), value => value.value);
  const tagNameColumnProps = useColumnSearchFilterProps('tagName', tagName);
  const store = useStore();

  React.useEffect(() => {
    setData(props.tags.valueOrEmpty());
  }, [props.tags]);

  React.useEffect(() => {
    setDatabases(props.databases.valueOrEmpty());
  }, [props.databases]);

  const onClickTagName = (record: ManageTag) => {
    console.log('View details for ' + record.tagName);
    setEditTagId(record.key)
    setEditTagName(record.tagName)
    setEditTagDatabases(getDatabasesPerTag(record.companyIds, databases))
  }

  const getDatabasesPerTag = (companyIds: number[], databases: ManageDatabase[]): ManageDatabase[] => {
    return databases.filter(database => companyIds.includes(database.companyId));
  }

  const onClickDeleteTag = (tagToDeleteOption: Option<ManageTag>) => {
    hideDeleteTagConfirmationModal();

    tagToDeleteOption
      .doIfSome(
        tagToDelete => deleteTag(store, tagToDelete.key)
          .doIfFailure(error => props.onDeleteTagsError(Option.some("An error occurred when attempting to delete the tag.")))
      )
      .doIfNone(
        () => props.onDeleteTagsError(Option.some("An error occurred when attempting to delete the tag."))
      )
  }

  const showDeleteTagConfirmationModal = (record: ManageTag) => {
    setSelectedTagForDeletion(Option.some(record));
    setIsDeleteTagsModalVisible(true);
  }

  const hideDeleteTagConfirmationModal = () => {
    setSelectedTagForDeletion(Option.none);
    setIsDeleteTagsModalVisible(false);
  }

  const columns: ColumnsType<ManageTag> = [
    {
      title: 'Tag name',
      dataIndex: 'tagName',
      defaultSortOrder: 'ascend',
      render: (tagName: string, record: ManageTag) => <Link to={routes.editTag.get(record.key)} onClick={() => onClickTagName(record)}>{tagName}</Link>,
      sorter: (a, b) => a.tagName.localeCompare(b.tagName),
      ...tagNameColumnProps,
      ellipsis: true,
    },
    {
      title: 'Number of databases',
      dataIndex: 'companyIds',
      render: (companyIds: number[], record: ManageTag) => companyIds.length,
      sorter: (a, b) => a.companyIds.length - b.companyIds.length,
      align: 'right',
        width: '16%',
    },
    {
      title: '',
      render: () => '',
    },
    {
      title: 'Delete',
      dataIndex: 'key',
      render: (key: string, record: ManageTag) => <Button type="link" danger onClick={() => showDeleteTagConfirmationModal(record)}>Delete tag</Button>,
      width: '16%',
    }
  ];

  const [ virtualizeTable ] = useVT(() => ({ scroll: { y: 600 } }), []);
  return <React.Fragment>
    <Modal
      className={styles.confirmDeleteTagModal}
      title="Delete tag?"
      centered
      visible={isDeleteTagsModalVisible}
      closable={false}
      width='300px'
      footer={[
        <Button key="delete tag cancel button" aria-label="delete tag cancel button" onClick={() => { hideDeleteTagConfirmationModal(); }}>
          Cancel
      </Button>,
        <Button key="delete tag button" aria-label="delete tag button" danger type="primary" onClick={() => { onClickDeleteTag(selectedTagForDeletion); }}>
          Delete tag
      </Button>,
      ]}
    />

    <div className={styles.actionBar}>
      List of database tags.
    </div>
    <Table
      aria-label="list of tags"
      loading={!props.tags.hasValue()}
      dataSource={data}
      columns={columns}
      pagination={false}
      rowKey={row => row.key}
      scroll={props.scroll}
      components={virtualizeTable}
    />
  </React.Fragment>;
}