import { Option } from 'functional-ts-primitives';

export interface UpdateWindow {
  startUtc: Date,
  endUtc: Date
}

export const toUpdateWindowLocalTimesString = (window: UpdateWindow) =>
  toTimeRangeString(window.startUtc, window.endUtc);

const toTimeRangeString = (start: Date, end: Date) =>
  `${getHourFromDate(start)}:${getMinutesFromDate(start)} ${getPeriodFromDate(start)} - ${getHourFromDate(end)}:${getMinutesFromDate(end)} ${getPeriodFromDate(end)}`;

const getMinutesFromDate = (date: Date) => date.getMinutes() >= 10 ? date.getMinutes() : `0${date.getMinutes()}`;
const getHourFromDate = (date: Date) => date.getHours() % 12 ? date.getHours() % 12 : 12;
const getPeriodFromDate = (date: Date) => date.getHours() >= 12 ? 'pm' : 'am';

export const compareUpdateWindowOptions = (updateWindow1: Option<UpdateWindow>, updateWindow2: Option<UpdateWindow>): number =>
  updateWindow1.match(some => getTimeOfDayInMilliseconds(some.startUtc), () => 99999999) - updateWindow2.match(some => getTimeOfDayInMilliseconds(some.startUtc), () => 99999999);

const getTimeOfDayInMilliseconds = (date: Date) : number =>
  date.getHours() * 3600000
    + date.getMinutes() * 60000
    + date.getSeconds() * 1000
    + date.getMilliseconds();