import { UpdateDatabase, ManageTag } from "models";

export const transformUpdateDatabasesToIncludeTags = (
  databases: UpdateDatabase[],
  databaseTags: ManageTag[]
): UpdateDatabase[] => {
  let tagsHash: Map<number, { key: string; tagName: string }[]> = getTagsHash(
    databaseTags
  );
  return databases.map((database) => ({
    ...database,
    tags: tagsHash.get(database.companyId) ?? [],
  }));
};

const getTagsHash = (
  tags: ManageTag[]
): Map<number, { key: string; tagName: string }[]> => {
  let tagsHash: Map<number, { key: string; tagName: string }[]> = new Map<
    number,
    { key: string; tagName: string }[]
  >();
  tags.forEach((tag) => {
    tag.companyIds.forEach((id) => {
      tagsHash.set(id, [
        ...(tagsHash.get(id) ?? []),
        { key: tag.key, tagName: tag.tagName },
      ]);
    });
  });
  return tagsHash;
};