import React from 'react';
import styles from "./GenerateBridgeScripts.module.scss";
import { compareVersionNumbers, toVersionNumber, toVersionNumberString, VersionNumber } from 'models';
import { Button, Select, Typography } from '@iqmetrix/antd';
import { Option } from 'functional-ts-primitives';
import { getBridgeScriptByStartAndStopVersions } from 'services';
import { ServiceError } from 'errors';
import { ServiceAlert } from "components";
import { saveAs } from 'file-saver';

const { Paragraph } = Typography;

export const GenerateBridgeScripts: React.FC<{
  versions: Option<VersionNumber[]>
}> = props => {
    const versionOptionsForSelection = props.versions.match(
        versionArray => versionArray
            .sort((a, b) => compareVersionNumbers(Option.some(b), Option.some(a)))
            .map(version => ({value: toVersionNumberString(version)})),
        () => []
    );

    const [selectedStartVersion, setSelectedStartVersion] = React.useState<string>('');
    const [selectedStopVersion, setSelectedStopVersion] = React.useState<string>('');
    const [isDownloading, setIsDownloading] = React.useState<boolean>(false);
    const [downloadScriptError, setDownloadScriptError] = React.useState<Option<ServiceError>>(Option.none());
  
    React.useEffect(() => {
        defaultVersionSelectorsToMostRecentVersion();
    }, [props.versions.hasValue()])

    const defaultVersionSelectorsToMostRecentVersion = () =>
    {
        setSelectedStartVersion(versionOptionsForSelection[0]?.value ?? '');
        setSelectedStopVersion(versionOptionsForSelection[0]?.value ?? '');
    };

    const selectedVersionsAreValid = 
        toVersionNumber(selectedStartVersion).hasValue()
        && toVersionNumber(selectedStopVersion).hasValue()
        && compareVersionNumbers(toVersionNumber(selectedStartVersion), toVersionNumber(selectedStopVersion)) < 0;

    const downloadBridgeScript = () =>
    {
        setIsDownloading(true);
        getBridgeScriptByStartAndStopVersions(selectedStartVersion, selectedStopVersion)
            .do(
                fileResponse => saveAs(fileResponse.file, fileResponse.fileName),
                () => {})
            .doAlways(() => setIsDownloading(false))
            .applyIfFailure(error => setDownloadScriptError(Option.some(error)));     
    };

  return <React.Fragment>
    {downloadScriptError.match(error => <ServiceAlert
      serviceError={error}
      ariaLabel="download script error alert"
      message={<span>An error occurred when attempting to download the bridge script: {error.message}</span>}
      type="error"
      showIcon
    />,
      () => null)}
    <div style={{width:400}}>
        <div>
            <Paragraph className={styles.versionSelectorDescription}>Initial version</Paragraph>
            <Select
            aria-label="start version selector"
            showSearch
            className={styles.versionSelector}
            value={selectedStartVersion}
            onSelect={e => setSelectedStartVersion(e)}
            loading={!props.versions.hasValue()}
            options={versionOptionsForSelection}
            filterOption={(input, option) => option?.value.indexOf(input) >= 0}/>
        </div>
        <div>
            <Paragraph className={styles.versionSelectorDescription}>Update to version</Paragraph>
            <Select
            aria-label="stop version selector"
            showSearch
            className={styles.versionSelector}        
            value={selectedStopVersion}
            onSelect={e => setSelectedStopVersion(e)}
            loading={!props.versions.hasValue()}
            options={versionOptionsForSelection}
            filterOption={(input, option) => option?.value.indexOf(input) >= 0}/>
        </div>

        <Button 
        type="primary"
        className={styles.fileDownloadButton}
        aria-label="download bridge script button" 
        disabled={!selectedVersionsAreValid || isDownloading} 
        loading={isDownloading} 
        onClick={downloadBridgeScript}>
            Download File
        </Button>
    </div> 
  </React.Fragment>;
}