import React, { useState } from 'react';
import { Table, Button, message, Alert, Tooltip, Typography } from '@iqmetrix/antd';
import { ColumnsType } from 'antd/es/table';
import styles from "./UpdateDetailDatabasesTable.module.scss";
import { UpdateDetail, UpdateStatusTypes, UpdateWindow, toUpdateWindowLocalTimesString, compareUpdateWindowOptions, getUpdateStatusTypesFilter, compareVersionNumbers, toVersionNumber } from 'models';
import { Option } from 'functional-ts-primitives';
import { OptionMatch, ServiceAlert } from 'components';
import { TableRowSelection } from 'antd/es/table/interface';
import { showConfirmationModal, ConfirmationModalOptions } from 'shared/confirmationModals';
import { routes } from 'shared/routes';
import { useHistory } from 'react-router-dom';
import { cancelScheduledUpdates, getUpdateDetails } from "services";
import { ServiceError } from 'errors';
import { useStoreDispatch, useStoreSelector } from 'store';
import { useVT } from 'virtualizedtableforantd4';
import { uniqBy } from 'lodash';
import { useColumnSearchFilterProps } from '../../hooks';

const { Text } = Typography;

export const UpdateDetailDatabasesTable: React.FC<{
  data: Option<UpdateDetail[]>, scroll?: {
    x?: number | true | string;
    y?: number | string;
  }
}> = props => {
  const history = useHistory();
  const { addPendingCancellations, removePendingCancellations } = useStoreDispatch();
  const pendingCancellations = useStoreSelector(state => state.pendingCancellations.pendingScheduleIds);
  const [cancellationRequestErrorDBs, setCancellationRequestErrorDBs] = useState<UpdateDetail[]>([]);
  const [pendingCancellationsWhichCompletedOrFailedDBs, setPendingCancellationsWhichCompletedOrFailedDBs] = useState<string[]>([]);
  const [cancelError, setCancelError] = useState<Option<ServiceError>>(Option.none());
  const [showCancellationRequestErrorDBs, setShowCancellationRequestErrorDBs] = useState<boolean>(false);
  const [showPendingCancellationsWhichCompletedOrFailedDBs, setShowPendingCancellationsWhichCompletedOrFailedDBs] = useState<boolean>(false);
  const [data, setData] = useState<UpdateDetail[]>(props.data.valueOrEmpty());
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<string[]>([]);
  const name = uniqBy(props.data.valueOrEmpty().map(mapping => mapping.database.name).map(name => ({ text: name, value: name })), value => value.value);
  const nameColumnProps = useColumnSearchFilterProps('name', name);
  const currentVersion = uniqBy(props.data.valueOrEmpty().map(mapping => mapping.database.currentVersion).map(currentVersion => ({ text: currentVersion, value: currentVersion })), value => value.text);
  const currentVersionColumnProps = useColumnSearchFilterProps('currentVersion', currentVersion);
  const server = uniqBy(props.data.valueOrEmpty().map(mapping => mapping.database.server).map(server => ({ text: server, value: server })), value => value.text);
  const serverColumnProps = useColumnSearchFilterProps('server', server);
  React.useEffect(() => {
    setData(props.data.valueOrEmpty());
    setCancelError(Option.none());
    setSelectedRowKeys([]);
    setCancellationRequestErrorDBs([]);
    if (props.data.valueOrEmpty().length > 0)
    {
      handlePendingCancellationsWhichCompletedOrFailed();
    }
  }, [props.data])

  const isAllowedToCancel = (record: UpdateDetail): boolean => {
    let statusString = (typeof record.status === "number") ? UpdateStatusTypes[record.status] : record.status;
    return (statusString === UpdateStatusTypes[UpdateStatusTypes.Pending]
      || statusString === UpdateStatusTypes[UpdateStatusTypes.Scheduled]
      || statusString === UpdateStatusTypes[UpdateStatusTypes.Triggered]
      || statusString === UpdateStatusTypes[UpdateStatusTypes.Running])
      && (record.database.updateWindow as Option<UpdateWindow>).hasValue();
  }

  const onChange = (rowKeys: string[]) => setSelectedRowKeys(rowKeys);
  const rowSelection = {
    selectedRowKeys,
    onChange,
    getCheckboxProps: record => ({ disabled: !isAllowedToCancel(record as UpdateDetail) })
  } as TableRowSelection<Record<string, any>>;

  let cancelDatabasesModal: ConfirmationModalOptions = {
    title: 'Cancel Update?',
    content: 'Selected updates will be cancelled',
    okText: 'Cancel update',
    cancelText: 'Do not Cancel',
    onOk: () => cancelUpdatesByScheduleIds(selectedRowKeys),
    onCancel: () => { }
  };

  const cancelUpdatesByScheduleIds = (scheduleIds: string[]) =>
  {
    cancelScheduledUpdates(scheduleIds).match(failedCancellationData => {
      getUpdateDetails(scheduleIds)
        .match(updateDetailData => {
          if (updateDetailData.every(item => UpdateStatusTypes[item.status] === UpdateStatusTypes[UpdateStatusTypes.Cancelled])) {
            history.replace(routes.scheduledUpdates.get());
            message.success("Updates cancelled.", 5);
          }else{
            setData(data.filter(item => !scheduleIds.includes(item.scheduleId)).concat(updateDetailData));
            setSelectedRowKeys([]);

            addPendingCancellations(updateDetailData.filter(item => 
              !isUpdateFinished(item.status))
              .map(item => item.scheduleId))
            setPendingCancellationsWhichCompletedOrFailedDBs(updateDetailData.filter(item => 
              UpdateStatusTypes[item.status] === UpdateStatusTypes[UpdateStatusTypes.Completed] 
              || UpdateStatusTypes[item.status] === UpdateStatusTypes[UpdateStatusTypes.Failed])
              .map(item => item.database.name));
            
            let cancellationRequestFailures = failedCancellationData.map(x => x.scheduleId);
            if (cancellationRequestFailures.length > 0) {
              setCancellationRequestErrorDBs(data.filter(item => 
                cancellationRequestFailures.includes(item.scheduleId)
                && !isUpdateFinished(item.status)))
            }
          }
      }, error => setCancelError(Option.some(error)));
    }, error => setCancelError(Option.some(error)));
  }

  const isUpdateFinished = (status: UpdateStatusTypes) =>
  {
    return UpdateStatusTypes[status] === UpdateStatusTypes[UpdateStatusTypes.Cancelled] 
      || UpdateStatusTypes[status] === UpdateStatusTypes[UpdateStatusTypes.Completed] 
      || UpdateStatusTypes[status] === UpdateStatusTypes[UpdateStatusTypes.Failed]
  }

  const handlePendingCancellationsWhichCompletedOrFailed = () => {
    let completedOrFailedUpdates = props.data.valueOrEmpty().filter(item => 
      pendingCancellations.includes(item.scheduleId) 
        && (UpdateStatusTypes[item.status] === UpdateStatusTypes[UpdateStatusTypes.Completed] 
        || UpdateStatusTypes[item.status] === UpdateStatusTypes[UpdateStatusTypes.Failed]));
    let cancelledUpdates = props.data.valueOrEmpty().filter(item => 
      pendingCancellations.includes(item.scheduleId) 
        && UpdateStatusTypes[item.status] === UpdateStatusTypes[UpdateStatusTypes.Cancelled]);

    setPendingCancellationsWhichCompletedOrFailedDBs(completedOrFailedUpdates.map(item => item.database.name));
    removePendingCancellations(completedOrFailedUpdates.concat(cancelledUpdates).map(item => item.scheduleId));
  }

  const toggleShowCancellationRequestErrorDBs = () => {
    setShowCancellationRequestErrorDBs(!showCancellationRequestErrorDBs);
  }

  const toggleShowPendingCancellationsWhichCompletedOrFailedDBs = () => {
    setShowPendingCancellationsWhichCompletedOrFailedDBs(!showPendingCancellationsWhichCompletedOrFailedDBs);
    }


  const columns: ColumnsType<UpdateDetail> = [
       {
            title: 'Database name',
            dataIndex: ['database', 'name'],
            sorter: (a, b) => a.database.name.localeCompare(b.database.name),
            render: (value, record) => <React.Fragment>{value} <Text className={styles.replicationText}>{record.database.replicationEnabled ? "(replication)" : ""}</Text></React.Fragment>,
            ...nameColumnProps
        },
        {
            title: 'Current version',
            dataIndex: ['database', 'currentVersion'],
            sorter: (a, b) => compareVersionNumbers(toVersionNumber(a.database.currentVersion), toVersionNumber(b.database.currentVersion)),
            ...currentVersionColumnProps,
            ellipsis: true,
        },
        {
            title: 'Server',
            dataIndex: ['database', 'server'],
            sorter: (a, b) => a.database.server.localeCompare(b.database.server),
            ...serverColumnProps,
            ellipsis: true,
        },
        {
            title: <Tooltip title="Refers to database preferred update window and showing logged in users timezone.">Preferred window</Tooltip>,
            dataIndex: ['database', 'updateWindow'],
            sorter: (a, b) => compareUpdateWindowOptions(a.database.updateWindow, b.database.updateWindow),
            showSorterTooltip: false,
            render: (windowOption: Option<UpdateWindow>) =>
                <OptionMatch
                    option={windowOption}
                    some={window => <React.Fragment>{toUpdateWindowLocalTimesString(window)}</React.Fragment>}
                    none={() => <React.Fragment>Not set</React.Fragment>} />
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status: UpdateStatusTypes) => {
                let statusText = (typeof status === "number") ? UpdateStatusTypes[status] : status;
                return <span className={getStatusBadgeStyleClassName(statusText)}>{statusText}</span>;
            },
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
            sorter: (a: UpdateDetail, b: UpdateDetail) => statusSorter(a.status, b.status),
            filters: getUpdateStatusTypesFilter(),
            filterMultiple: true,
            onFilter: (value: any, record: UpdateDetail) => record.status.toString().indexOf(value) === 0,
            ellipsis: true,
        }
    ];

  
  const [ virtualizeTable ] = useVT(() => ({ scroll: { y: 600 } }), []);
  return <React.Fragment>
    {cancellationRequestErrorDBs.length > 0 && <Alert
        aria-label="cancellationRequestErrorDBs alert"
        message={<span>While attempting to save changes, <b>{cancellationRequestErrorDBs.length + `${cancellationRequestErrorDBs.length > 1 ? ' databases were' : ' database was'}`}</b> not cancelled. Please <span className={styles.failedCancellationMessageButton} onClick={() => cancelUpdatesByScheduleIds(cancellationRequestErrorDBs.map(item => item.scheduleId))}>try again.</span></span>}
        description={
          <div>
            <ul>
              {
                showCancellationRequestErrorDBs ? cancellationRequestErrorDBs.map(item => <li key={item.scheduleId}>{item.database.name}</li>) : []
              }
            </ul>
            {<span className={styles.failedCancellationMessageButton} onClick={toggleShowCancellationRequestErrorDBs}>{showCancellationRequestErrorDBs ? 'hide details' : 'show details'}</span>}
          </div>
        }
        type="error"
        showIcon
      />}

    {pendingCancellationsWhichCompletedOrFailedDBs.length > 0 && <Alert
        aria-label="pendingCancellationsWhichCompletedOrFailedDBs alert"
        message={<span>While attempting to cancel, <b>{pendingCancellationsWhichCompletedOrFailedDBs.length + ' database'}</b> {`${pendingCancellationsWhichCompletedOrFailedDBs.length > 1 ? 'updates' : 'update'}`} completed (failed to cancel).</span>}
        description={
          <div>
            <ul>
              {
                showPendingCancellationsWhichCompletedOrFailedDBs ? pendingCancellationsWhichCompletedOrFailedDBs.map(item => <li key={item}>{item}</li>) : []
              }
            </ul>
            {<span className={styles.failedCancellationMessageButton} onClick={toggleShowPendingCancellationsWhichCompletedOrFailedDBs}>{showPendingCancellationsWhichCompletedOrFailedDBs ? 'hide details' : 'show details'}</span>}
          </div>
        }
        type="error"
        showIcon
      />}

    {cancelError.match(error => <ServiceAlert
      serviceError={error}
      message={error.message}
      type="error"
      showIcon
    />,
      () => null)}

    <div className={styles.actionBar}>
      {data.length > 0 ? <div>{selectedRowKeys.length} of {data.length} databases selected</div> : <div>0 databases</div>}
      <div aria-label="cancelUpdate button div" className={selectedRowKeys.length > 0 ? styles.showCancelUpdateButton : styles.hideCancelUpdateButton}>
        <Button type="link" danger aria-label="cancelUpdate button" onClick={() => showConfirmationModal(cancelDatabasesModal)}>{'Cancel update'}</Button>
      </div>
    </div>
    <Table
      rowSelection={rowSelection}
      dataSource={data}
      columns={columns}
      pagination={false}
      scroll={props.scroll}
      loading={!props.data.hasValue()} 
      components={virtualizeTable} />
  </React.Fragment>;
}

const getStatusBadgeStyleClassName = (status: string): string => {
  let badgeStyles = [styles.statusBadge];
  switch (status) {
    case UpdateStatusTypes[UpdateStatusTypes.Pending]: {
      badgeStyles.push(styles.pending);
      break
    }
    case UpdateStatusTypes[UpdateStatusTypes.Running]: {
      badgeStyles.push(styles.running);
      break
    }
    case UpdateStatusTypes[UpdateStatusTypes.Failed]: {
      badgeStyles.push(styles.failed);
      break
    }
    case UpdateStatusTypes[UpdateStatusTypes.Cancelling]: {
      badgeStyles.push(styles.cancelling);
      break
    }
    case UpdateStatusTypes[UpdateStatusTypes.Cancelled]: {
      badgeStyles.push(styles.cancelled);
      break
    }
    case UpdateStatusTypes[UpdateStatusTypes.Completed]: {
      badgeStyles.push(styles.completed);
      break
    }
    case UpdateStatusTypes[UpdateStatusTypes.Scheduled]: {
      badgeStyles.push(styles.scheduled)
      break;
    }
    case UpdateStatusTypes[UpdateStatusTypes.Triggered]: {
      badgeStyles.push(styles.triggered)
      break;
    }
    default:
      break;
  }
  return badgeStyles.join(' ');
}

const statusSorter = (status1: UpdateStatusTypes, status2: UpdateStatusTypes): number => {
  if (status1 > status2) {
    return 1;
  }
  else if (status1 < status2) {
    return -1;
  }
  else {
    return 0;
  }
}