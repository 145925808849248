import { Option } from "functional-ts-primitives";

export interface OptionMatchProps<TValue> {
    option: Option<TValue>
    some: (some: TValue) => React.ReactElement
    none: () => React.ReactElement
}

export const OptionMatch = <TValue>(props: OptionMatchProps<TValue>) =>
    props.option.match(
        someValue => props.some(someValue),
        () => props.none(),
    );