import { Alert, Button, Col, message, Row } from "@iqmetrix/antd";
import { PageLayout } from "@iqmetrix/layout";
import { AppPageHeader } from "components";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { routes } from "shared/routes";
import { useStore, useStoreDispatch, useStoreSelector } from "store";
import { Option } from "functional-ts-primitives";
import { ServiceError } from "errors";
import { updateTag } from "composition";
import { EditTagDatabasesCard } from "./EditTagDatabasesCard";
import { EditTagNameCard } from "./EditTagNameCard";

export const EditTagPage: React.FC = () => {
  const { clearEditTag } = useStoreDispatch();
  const editTagData = useStoreSelector((state) =>
    Option.zip(
      state.editTag.id,
      state.editTag.name,
      Option.some(state.editTag.databases)
    )
  );
  const history = useHistory();
  if (!editTagData.hasValue()) {
    history.push(routes.manageDatabases.get());
  }
  const onCancel = () => {
    clearEditTag();
    history.replace(routes.manageDatabases.get());
  };
  const store = useStore();

  const [isSaving, setIsSaving] = useState<boolean>();
  const [saveError, setSaveError] = useState<Option<ServiceError>>(Option.none());
  const [newTagName, setNewTagName] = useState<Option<string>>(Option.none<string>());
  const enableSave = editTagData.hasValue() && newTagName.hasValue() && editTagData.match(tag => tag[1], () => "") !== newTagName.valueOrDefault(() => "");
  const executeSave = () =>
    editTagData.bind(([id]) => newTagName.map(newName => ({ id, newName }))).doIfSome((data) => {
      const { id, newName } = data;
      setIsSaving(true);
      return updateTag(store, id, newName)
        .doIfFailure((error) => setSaveError(Option.some(error)))
        .doAlways(() => setIsSaving(false))
        .doIfSuccessful(() => {
          message.success("Changes saved.");
          history.replace(routes.manageDatabases.get());
        });
    });

  return (
    <PageLayout size="full">
      {{
        header: (
          <AppPageHeader
            title={editTagData.match(
              tag => tag[1],
              () => ""
            )}
            extra={
              <React.Fragment>
                <Link to={routes.manageDatabases.get()}>
                  <Button
                    type="default"
                    onClick={onCancel}
                    aria-label="cancel button"
                    disabled={isSaving}
                  >
                    Cancel
                  </Button>
                </Link>
                <Button
                  type="primary"
                  aria-label="save button"
                  disabled={!enableSave}
                  loading={isSaving}
                  onClick={executeSave}
                >
                  Save
                </Button>
              </React.Fragment>
            }
          />
        ),
        messages: saveError.match(
          error => <Alert type="error" message="An error occurred when attempting to update your tag." closable={true} onClose={() => setSaveError(Option.none())} aria-label="An error occurred on save alert" />,
          () => null),
        content: [
          {
            primary: (
              <Row gutter={[14, 17]} aria-label="edit tag page">
                <Col span={18}>
                  <EditTagDatabasesCard
                    databases={editTagData.match(tag => tag[2], () => [])} />
                </Col>
                <Col span={6}>
                  <EditTagNameCard
                    onValueChanged={value => setNewTagName(value === "" ? Option.none<string>() : Option.some(value))}
                    name={editTagData.match(tag => tag[1], () => "")} />
                </Col>
              </Row>
            ),
          },
        ],
      }}
    </PageLayout>
  );
};
