import { toVersionNumber, VersionMapping } from "models";

export interface VersionMappingResponse {
    serviceVersion: string|null
    minimumDatabaseVersion: string
}

export const toVersionMappings = (models: VersionMappingResponse[]) : VersionMapping[] =>
    models.map(model => toVersionMapping(model));

const toVersionMapping = (model: VersionMappingResponse) : VersionMapping => ({
    key: model.minimumDatabaseVersion,
    databaseVersion: toVersionNumber(model.minimumDatabaseVersion),
    oldestRQVersion: toVersionNumber(model.serviceVersion)
});