import { DatabaseTagsData } from "models";

export interface AddTagsToDatabaseRequest {
  id: number;
  tags: string[];
}

export const toAddTagsToDatabaseRequest = (model: DatabaseTagsData): AddTagsToDatabaseRequest => ({
  id: model.companyId,
  tags: model.tagIds
});

export const toAddTagsToDatabaseRequests = (models: DatabaseTagsData[]): AddTagsToDatabaseRequest[] =>
  models.map(toAddTagsToDatabaseRequest);